/**
 * @generated SignedSource<<ddb1619fbec5cb470349f3c87cba7a82>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type AuthLevelAdmin = "Admin" | "None" | "%future added value";
export type AuthLevelRead = "None" | "ReadOnly" | "%future added value";
export type AuthLevelWrite = "None" | "ReadOnly" | "ReadWrite" | "%future added value";
export type AuthenticationRole = "Administrator" | "AssetsReadOnly" | "AssetsReadWrite" | "ReadOnly" | "TasksBatteryHealthReadOnly" | "TasksBatteryHealthReadWrite" | "%future added value";
export type UserErrorType = "EmailAlreadyRegistered" | "InvalidUser" | "MissingEmail" | "MissingName" | "Noop" | "OperationNotAllowed" | "%future added value";
export type UserIn = {
  email: string;
  name: string;
  permissions?: UserPermissionsIn | null;
  roles?: ReadonlyArray<AuthenticationRole> | null;
};
export type UserPermissionsIn = {
  administration?: AuthLevelAdmin | null;
  assets?: AuthLevelWrite | null;
  batteryHealthTasks?: AuthLevelWrite | null;
  general?: AuthLevelRead | null;
};
export type savingCreateUserMutation$variables = {
  user: UserIn;
};
export type savingCreateUserMutation$data = {
  readonly addUser: {
    readonly problems?: ReadonlyArray<{
      readonly type: UserErrorType;
    }>;
    readonly username?: string;
  } | null;
};
export type savingCreateUserMutation = {
  response: savingCreateUserMutation$data;
  variables: savingCreateUserMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "user"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "user",
    "variableName": "user"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserProblem",
      "kind": "LinkedField",
      "name": "problems",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserProblemResponse",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "savingCreateUserMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "addUser",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "savingCreateUserMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "addUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d24d9439f33b0b2574f95e37046d108e",
    "id": null,
    "metadata": {},
    "name": "savingCreateUserMutation",
    "operationKind": "mutation",
    "text": "mutation savingCreateUserMutation(\n  $user: UserIn!\n) {\n  addUser(user: $user) {\n    __typename\n    ... on User {\n      username\n    }\n    ... on UserProblemResponse {\n      problems {\n        type\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b9013c597b64971e7a4227699679c808";

export default node;
