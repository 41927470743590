import React, { FC, useCallback } from 'react';
import { fetchQuery, useFragment, useMutation, useRelayEnvironment } from 'react-relay';

import { FormArea, useToast } from '@accesstel/pcm-ui';

import { captureException } from '@sentry/react';
import graphql from 'babel-plugin-relay/macro';
import { Formik, FormikHelpers } from 'formik';

import { PresetOptions, UnitPreset, stripUnknown } from '../lib/units';
import { UnitSettingsFormValues } from '../schema';
import { UnitSettingsValidationSchema } from '../validation';
import { UnitSettingsContent } from './UnitSettingsContent';
import { UnitSettingsFragment$data, UnitSettingsFragment$key } from './__generated__/UnitSettingsFragment.graphql';
import { UnitSettingsUpdateMutation, UserUnitPreferencesIn } from './__generated__/UnitSettingsUpdateMutation.graphql';

export interface UnitSettingsProps {
    settings: UnitSettingsFragment$key;
}

export const UnitSettings: FC<UnitSettingsProps> = ({ settings }) => {
    const { show } = useToast();
    const data = useFragment(UnitSettingsFragment, settings);

    const [saveUnitSettings] = useMutation<UnitSettingsUpdateMutation>(UpdateMutation);
    const environment = useRelayEnvironment();

    const handleSubmit = useCallback(
        (values: UnitSettingsFormValues, { setSubmitting, resetForm }: FormikHelpers<UnitSettingsFormValues>) => {
            const initialValues = toInitialValues(data);
            const submitData = toSubmitData(values, initialValues);

            if (!submitData) {
                setSubmitting(false);
                resetForm({
                    values,
                    touched: {},
                });
                // It didn't really, but it might as well have
                show({
                    text: 'Saved unit preferences',
                    variant: 'info',
                });
                return;
            }

            setSubmitting(true);
            saveUnitSettings({
                variables: {
                    input: submitData,
                },
                onCompleted: data => {
                    if (data.updateCurrentUserPreferences.success) {
                        show({
                            text: 'Saved unit preferences',
                            variant: 'info',
                        });

                        // Reload the form
                        fetchQuery(environment, ReloadQuery, {}).subscribe({
                            next() {
                                setSubmitting(false);
                            },
                            error(error: unknown) {
                                setSubmitting(false);
                                captureException(error, scope => {
                                    scope.setTag('Function', 'Unit settings reload');
                                    return scope;
                                });
                            },
                        });
                    } else {
                        setSubmitting(false);
                        show({
                            text: 'Unable to save changes. Please try again later',
                            variant: 'error',
                        });

                        // NOTE: There are no problems relating to units in the response.
                        // If this fails, it's likely a server error.
                    }
                },
                onError: error => {
                    setSubmitting(false);
                    show({
                        text: 'Unable to save changes. Please try again later',
                        variant: 'error',
                    });
                    captureException(error, scope => {
                        scope.setTag('Function', 'Personal unit settings save');
                        return scope;
                    });
                },
            });
        },
        [data, environment, saveUnitSettings, show]
    );

    return (
        <FormArea label='Units' blurb='Preferences for which units are displayed can be set or customised here.'>
            <Formik
                initialValues={toInitialValues(data)}
                validationSchema={UnitSettingsValidationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                <UnitSettingsContent />
            </Formik>
        </FormArea>
    );
};

export const UnitSettingsFragment = graphql`
    fragment UnitSettingsFragment on UserPreferences {
        units {
            temperature
            pressure
            volume
            volumetricFlowRate
        }
    }
`;

const UpdateMutation = graphql`
    mutation UnitSettingsUpdateMutation($input: UserUnitPreferencesIn!) {
        updateCurrentUserPreferences(preferences: { units: $input }) {
            success
            problems
        }
    }
`;

const ReloadQuery = graphql`
    query UnitSettingsReloadQuery {
        currentUser {
            preferences {
                ...UnitSettingsFragment
            }
        }
    }
`;

function toInitialValues(data: UnitSettingsFragment$data): UnitSettingsFormValues {
    const units = data.units;

    const decodedUnits = {
        temperature: stripUnknown(units.temperature),
        pressure: stripUnknown(units.pressure),
        volume: stripUnknown(units.volume),
        volumetricFlowRate: stripUnknown(units.volumetricFlowRate),
    };

    // Detect if the current configuration matches a preset
    let detectedPreset: UnitPreset = UnitPreset.Custom;

    for (const preset in PresetOptions) {
        const presetUnits = PresetOptions[preset as Exclude<UnitPreset, UnitPreset.Custom>];

        if (
            presetUnits.temperature === decodedUnits.temperature &&
            presetUnits.pressure === decodedUnits.pressure &&
            presetUnits.volume === decodedUnits.volume &&
            presetUnits.volumetricFlowRate === decodedUnits.volumetricFlowRate
        ) {
            detectedPreset = preset as UnitPreset;
            break;
        }
    }

    const defaults = PresetOptions[UnitPreset.Metric];

    return {
        preset: detectedPreset,
        temperature: decodedUnits.temperature ?? defaults.temperature,
        pressure: decodedUnits.pressure ?? defaults.pressure,
        volume: decodedUnits.volume ?? defaults.volume,
        volumetricFlowRate: decodedUnits.volumetricFlowRate ?? defaults.volumetricFlowRate,
    };
}

function toSubmitData(
    values: UnitSettingsFormValues,
    initialValues: UnitSettingsFormValues
): UserUnitPreferencesIn | null {
    const submitData: UserUnitPreferencesIn = {};

    let changed = false;
    if (values.temperature !== initialValues.temperature) {
        submitData.temperature = values.temperature;
        changed = true;
    }

    if (values.pressure !== initialValues.pressure) {
        submitData.pressure = values.pressure;
        changed = true;
    }

    if (values.volume !== initialValues.volume) {
        submitData.volume = values.volume;
        changed = true;
    }

    if (values.volumetricFlowRate !== initialValues.volumetricFlowRate) {
        submitData.volumetricFlowRate = values.volumetricFlowRate;
        changed = true;
    }

    if (!changed) {
        return null;
    }

    return submitData;
}
