import { Dispatch, Reducer, useEffect, useReducer, useRef } from 'react';
import { useLazyLoadQuery, useMutation } from 'react-relay';
import { useLocation } from 'react-router-dom';

import { useExtendedNavigate } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { logError } from 'lib/log';
import { useHistoryState } from 'lib/saved-state';
import { createSortParams, useSearchParamSortBy } from 'lib/table-sort';
import isEqual from 'lodash.isequal';

import { ColumnWithId } from './TableLayout';
import { reducerCurrentUserTablePreferenceQuery } from './__generated__/reducerCurrentUserTablePreferenceQuery.graphql';
import { reducerUpdateTablePreferenceColumnsMutation } from './__generated__/reducerUpdateTablePreferenceColumnsMutation.graphql';
import { InternalTableState, SortDirection, TableState } from './state';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function debounce(func: (...args: any[]) => void, wait: number) {
    let timeout: NodeJS.Timeout;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return function (...args: any[]) {
        clearTimeout(timeout);
        timeout = setTimeout(() => func(...args), wait);
    };
}

export enum TableActionType {
    SortColumn,
    ChangePage,
    UpdateSearch,
    SetSelection,
    AppendSelection,
    SetAllSelectableItems,
    UpdateColumns,
    ResetColumns,
    SetColumnEditPaneOpen,
    SetExportPaneOpen,
}

export type TableAction<ColumnType extends string> =
    | {
          type: TableActionType.SortColumn;
          column: ColumnType;
          direction: SortDirection;
      }
    | { type: TableActionType.ChangePage; page: number }
    | { type: TableActionType.UpdateSearch; search: string }
    | { type: TableActionType.SetSelection; selection: string[] }
    | { type: TableActionType.AppendSelection; selection: string[] }
    | { type: TableActionType.SetAllSelectableItems; items: string[] | null }
    | { type: TableActionType.UpdateColumns; columns: ColumnType[] }
    | { type: TableActionType.ResetColumns }
    | { type: TableActionType.SetColumnEditPaneOpen; isOpen: boolean }
    | { type: TableActionType.SetExportPaneOpen; isOpen: boolean };

export interface TableReducerOptions<ColumnType extends string> {
    defaultSortColumn: ColumnType;
    defaultSortDirection?: SortDirection;
    defaultVisibleColumns: ColumnType[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    allColumns: ColumnWithId<ColumnType, any>[];
    storageKeyPrefix: string;
    initialSelection?: string[];
    sortableColumns?: ColumnType[];
    defaultPageSize?: number;

    disableUrlSortSync?: boolean;
}

export function useTableReducer<ColumnType extends string>(
    options: TableReducerOptions<ColumnType>
): [TableState<ColumnType>, Dispatch<TableAction<ColumnType>>] {
    const location = useLocation();
    const navigate = useExtendedNavigate();

    const [mutate] = useMutation<reducerUpdateTablePreferenceColumnsMutation>(updateTablePreferenceColumnsMutation);
    const mutateCallback = (columns: ColumnType[] | null) => {
        mutate({
            variables: {
                tableId: options.storageKeyPrefix,
                columns,
            },
            onCompleted: response => {
                if (!response.updateCurrentUserTablePreferences.success) {
                    logError(
                        'Failed to update user table preferences',
                        response.updateCurrentUserTablePreferences.problems
                    );
                }
            },
            onError: error => {
                logError('Failed to update user table preferences', error);
            },
        });
    };

    const runMutation = useRef(debounce(mutateCallback, 1_000)).current;

    if (!options.sortableColumns) {
        options.sortableColumns = options.allColumns.filter(column => column.meta?.sortable).map(column => column.id);
    }

    const sorter = useSearchParamSortBy(options.sortableColumns);

    const columnPreferencesData = useLazyLoadQuery<reducerCurrentUserTablePreferenceQuery>(
        tablePreferenceColumnsQuery,
        {
            tableId: options.storageKeyPrefix,
        }
    );

    let visibleColumnsInOrder: ColumnType[] = [];
    if (
        columnPreferencesData?.currentUser.tablePreferences?.columns &&
        columnPreferencesData.currentUser.tablePreferences.columns.length > 0
    ) {
        const columns = columnPreferencesData.currentUser.tablePreferences.columns as ColumnType[];
        // validate the columns based on the allColumns
        for (const column of columns) {
            if (!options.allColumns.find(c => c.id === column)) {
                logError(`Invalid column found in user preferences: ${column}`);
                continue;
            }
            visibleColumnsInOrder.push(column);
        }
    } else {
        visibleColumnsInOrder = options.defaultVisibleColumns;
    }

    const [storedInitialState, setStoredInitialState] = useHistoryState<
        Pick<InternalTableState<ColumnType>, 'page' | 'search'>
    >('table-state', {
        page: 1,
        search: '',
    });

    function tableReducer(
        state: InternalTableState<ColumnType>,
        action: TableAction<ColumnType>
    ): InternalTableState<ColumnType> {
        switch (action.type) {
            case TableActionType.SortColumn:
                if (action.column === state.sortColumn && action.direction === state.sortDirection) {
                    return state;
                }

                return {
                    ...state,
                    sortColumn: action.column,
                    sortDirection: action.direction,
                };
            case TableActionType.ChangePage:
                if (action.page === state.page) {
                    return state;
                }

                return {
                    ...state,
                    page: Math.max(1, action.page),
                };
            case TableActionType.UpdateSearch:
                if (action.search === state.search) {
                    return state;
                }

                return {
                    ...state,
                    search: action.search,
                };
            case TableActionType.SetSelection:
                if (isEqual(action.selection, state.selectedItems)) {
                    return state;
                }
                return {
                    ...state,
                    selectedItems: action.selection,
                };
            case TableActionType.AppendSelection: {
                const selectedItems = new Set<string>(state.selectedItems.concat(action.selection));
                const newSelectedItems = Array.from(selectedItems);

                if (isEqual(newSelectedItems, state.selectedItems)) {
                    return state;
                }

                return {
                    ...state,
                    selectedItems: newSelectedItems,
                };
            }
            case TableActionType.SetAllSelectableItems:
                if (isEqual(action.items, state.allSelectableItems)) {
                    return state;
                }

                return {
                    ...state,
                    allSelectableItems: action.items,
                };
            case TableActionType.UpdateColumns: {
                if (isEqual(action.columns, state.visibleColumnsInOrder)) {
                    return state;
                }

                const updatedState = {
                    ...state,
                    visibleColumnsInOrder: action.columns,
                };

                runMutation(updatedState.visibleColumnsInOrder);

                return updatedState;
            }
            case TableActionType.ResetColumns: {
                if (state.visibleColumnsInOrder == null) {
                    return state;
                }

                const updatedState = {
                    ...state,
                    visibleColumnsInOrder: null,
                };

                runMutation(updatedState.visibleColumnsInOrder);

                return updatedState;
            }
            case TableActionType.SetColumnEditPaneOpen:
                if (action.isOpen === state.isColumnEditPaneOpen) {
                    return state;
                }

                return {
                    ...state,
                    isColumnEditPaneOpen: action.isOpen,
                };
            case TableActionType.SetExportPaneOpen:
                if (action.isOpen === state.isExportPaneOpen) {
                    return state;
                }

                return {
                    ...state,
                    isExportPaneOpen: action.isOpen,
                };
        }
    }

    const [state, dispatch] = useReducer(
        tableReducer as Reducer<InternalTableState<ColumnType>, TableAction<ColumnType>>,
        undefined,
        () => {
            const initialState: InternalTableState<ColumnType> = {
                sortColumn: sorter ? sorter.sortColumn : options.defaultSortColumn,
                sortDirection: sorter ? sorter.sortDirection : options.defaultSortDirection ?? SortDirection.Ascending,
                page: storedInitialState.page,
                search: storedInitialState.search,
                selectedItems: options.initialSelection ?? [],
                allSelectableItems: null,
                visibleColumnsInOrder,
                isColumnEditPaneOpen: false,
                isExportPaneOpen: false,
                defaultVisibleColumns: options.defaultVisibleColumns,
                pageSize: options.defaultPageSize ?? 50,
            };

            // load default search value from URL
            if (location.search) {
                const query = new URLSearchParams(location.search);
                initialState.search = query.get('q') ?? '';
            }

            return initialState;
        }
    );

    useEffect(() => {
        setStoredInitialState({
            page: state.page,
            search: state.search,
        });

        if (options.disableUrlSortSync) {
            return;
        }

        navigate(
            {
                pathname: location.pathname,
                search: createSortParams(state.sortColumn, state.sortDirection),
            },
            { replace: true }
        );

        // NOTE: setStoredInitialState is not included or it will cause a render loop
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.sortColumn, state.sortDirection, state.page, state.search, options.disableUrlSortSync]);

    const outputState: TableState<ColumnType> = {
        ...state,
        visibleColumnsInOrder: state.visibleColumnsInOrder ?? options.defaultVisibleColumns,
        areProvidedColumnsInDefaultOrder(columns) {
            const providedHasDefaultOrder = isEqual(columns, state.defaultVisibleColumns);
            const stateHasDefaultOrder =
                state.visibleColumnsInOrder === null ||
                isEqual(state.visibleColumnsInOrder, state.defaultVisibleColumns);

            return providedHasDefaultOrder && stateHasDefaultOrder;
        },
    };

    return [outputState, dispatch];
}

const updateTablePreferenceColumnsMutation = graphql`
    mutation reducerUpdateTablePreferenceColumnsMutation($tableId: ID!, $columns: [ID!]) {
        updateCurrentUserTablePreferences(tableId: $tableId, preferences: { columns: $columns }) {
            success
            problems
        }
    }
`;

const tablePreferenceColumnsQuery = graphql`
    query reducerCurrentUserTablePreferenceQuery($tableId: ID!) {
        currentUser {
            tablePreferences(tableId: $tableId) {
                id
                columns
            }
        }
    }
`;
