/**
 * @generated SignedSource<<8ab47beada55dc28fe0398b2a4d8778e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GeneratorRunReportCard_data$data = {
  readonly " $fragmentSpreads": FragmentRefs<"RunParameterView_data" | "RunReportHeader_data" | "RunReportResultDisplay_data">;
  readonly " $fragmentType": "GeneratorRunReportCard_data";
};
export type GeneratorRunReportCard_data$key = {
  readonly " $data"?: GeneratorRunReportCard_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"GeneratorRunReportCard_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "chartPoints"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "GeneratorRunReportCard_data",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RunReportHeader_data"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RunParameterView_data"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "chartPoints",
          "variableName": "chartPoints"
        }
      ],
      "kind": "FragmentSpread",
      "name": "RunReportResultDisplay_data"
    }
  ],
  "type": "GeneratorRunReport",
  "abstractKey": null
};

(node as any).hash = "33c50d87e0cf28513a6c850928095a3d";

export default node;
