/**
 * @generated SignedSource<<b0399b75ed80f08e92d5188dfdd3b393>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type queries_AverageTemperatureSparklineQuery$variables = {
  begin: string;
  end: string;
  interval: string;
};
export type queries_AverageTemperatureSparklineQuery$data = {
  readonly batteryMetrics: {
    readonly temperature: {
      readonly trend: {
        readonly percent: number;
      } | null;
      readonly values: ReadonlyArray<{
        readonly timestamp: string;
        readonly value: number | null;
      }>;
    } | null;
  };
};
export type queries_AverageTemperatureSparklineQuery = {
  response: queries_AverageTemperatureSparklineQuery$data;
  variables: queries_AverageTemperatureSparklineQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "begin"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "end"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "interval"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "GlobalBatteryMetrics",
    "kind": "LinkedField",
    "name": "batteryMetrics",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "begin",
            "variableName": "begin"
          },
          {
            "kind": "Variable",
            "name": "end",
            "variableName": "end"
          },
          {
            "kind": "Variable",
            "name": "interval",
            "variableName": "interval"
          },
          {
            "kind": "Literal",
            "name": "unit",
            "value": "Celsius"
          }
        ],
        "concreteType": "TimeSeriesMetrics",
        "kind": "LinkedField",
        "name": "temperature",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TimestampMetric",
            "kind": "LinkedField",
            "name": "values",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "timestamp",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Trend",
            "kind": "LinkedField",
            "name": "trend",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "percent",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "queries_AverageTemperatureSparklineQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "queries_AverageTemperatureSparklineQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "62a8d9e026093998551239ac076a4ff5",
    "id": null,
    "metadata": {},
    "name": "queries_AverageTemperatureSparklineQuery",
    "operationKind": "query",
    "text": "query queries_AverageTemperatureSparklineQuery(\n  $begin: Timestamp!\n  $end: Timestamp!\n  $interval: Duration!\n) {\n  batteryMetrics {\n    temperature(unit: Celsius, begin: $begin, end: $end, interval: $interval) {\n      values {\n        timestamp\n        value\n      }\n      trend {\n        percent\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d767c515c2639a51c92f283857a3ee4b";

export default node;
