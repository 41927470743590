import React from 'react';

import { createColumnHelper } from '@tanstack/react-table';
import { TestResultTableColumn } from 'filters/test-result';
import { ColumnWithId } from 'layouts';
import { formatDurationAsHoursAndMinutes, formatMinutesAsHoursAndMinutes, getDateTimeFormat } from 'lib/dateFormatter';
import { numberToLocaleString } from 'lib/numberFormatters';
import { renderMetricCell, renderTableStatusCell } from 'lib/table-columns';
import { BatteryTestCauseMap, FailReasonMap } from 'lib/textFormatters';
import { DateTime } from 'luxon';

import { StatusColorMap, StatusType } from '../test-result-view/components/StatusLabel';
import {
    BatteryTestFailReason,
    BatteryTestReason,
    BatteryTestState,
    TestResultListQuery$data,
} from './__generated__/TestResultListQuery.graphql';

type TestResult = TestResultListQuery$data['unplannedTests']['data'][number];
type EstimatedValue = NonNullable<TestResult['estimatedCapacity']>;

const columnHelper = createColumnHelper<TestResult>();

function renderStatusCell(value: BatteryTestState) {
    return renderTableStatusCell(value);
}

function renderRunTimeCell(data: TestResult) {
    const from = data.commencedTime ? DateTime.fromISO(data.commencedTime) : DateTime.now();
    const to = data.completedTime;

    if (to) {
        return formatDurationAsHoursAndMinutes(from, DateTime.fromISO(to));
    } else {
        return formatDurationAsHoursAndMinutes(from, DateTime.now());
    }
}

function renderCauseCell(value: BatteryTestReason) {
    return BatteryTestCauseMap[value] ?? 'Unknown';
}

function renderEstimatedValue(value: EstimatedValue, unit: string): string | undefined {
    if (value.confidenceInterval) {
        const lower = numberToLocaleString(value.confidenceInterval.lower, 0);
        const upper = numberToLocaleString(value.confidenceInterval.upper, 0);
        return `${lower} - ${upper}${unit}`;
    }

    if (value.value == null) {
        return;
    }

    let prefix = '';
    if (value.type === 'Minimum') {
        prefix = 'Above ';
    } else if (value.type === 'Maximum') {
        prefix = 'Below ';
    }

    return `${prefix}${numberToLocaleString(value.value, 0)}${unit}`;
}

export const DeviceNameColumn = columnHelper.accessor('device.name', {
    id: TestResultTableColumn.DeviceName,
    header: 'Device Name',
    exportHeader: 'Device Name',
    meta: {
        filterable: true,
        sortable: true,
        maxWidth: '16rem',
    },
});
export const StateColumn = columnHelper.accessor('state', {
    id: TestResultTableColumn.State,
    header: 'Result',
    exportHeader: 'Test Result',
    cell: ({ cell }) => renderStatusCell(cell.getValue()),
    meta: {
        filterable: true,
        sortable: true,
    },
});
export const RunTimeColumn = columnHelper.display({
    id: TestResultTableColumn.RunTime,
    header: 'Run Time',
    exportHeader: 'Run Time (min)',
    cell: ({ row }) => renderRunTimeCell(row.original),
    exportValue: ({ row }) => {
        const from = row.commencedTime ? DateTime.fromISO(row.commencedTime) : DateTime.now();
        const to = row.completedTime;

        let minutes: number;
        if (to) {
            minutes = DateTime.fromISO(to).diff(from).as('minutes');
        } else {
            minutes = DateTime.now().diff(from).as('minutes');
        }

        return numberToLocaleString(minutes, 0);
    },
    meta: {
        filterable: true,
        sortable: true,
    },
});
export const CauseColumn = columnHelper.accessor('cause', {
    id: TestResultTableColumn.Cause,
    header: 'Discharge Cause',
    exportHeader: 'Discharge Cause',
    cell: ({ cell }) => renderCauseCell(cell.getValue()),
    meta: {
        filterable: true,
        sortable: true,
    },
});
export const StartTimeColumn = columnHelper.accessor('commencedTime', {
    id: TestResultTableColumn.StartTime,
    header: 'Date Created',
    exportHeader: 'Start Time',
    cell: ({ cell }) => getDateTimeFormat(cell.getValue() as string),
    exportValue: ({ value }) => new Date(value as string),
    meta: {
        filterable: true,
        sortable: true,
    },
});
export const FailReasonColumn = columnHelper.accessor('failReason', {
    id: TestResultTableColumn.FailReason,
    header: 'Fail Reason',
    exportHeader: 'Fail Reason',
    cell: ({ cell }) =>
        renderMetricCell(() => {
            if (!cell.getValue()) {
                return;
            }

            return FailReasonMap[cell.getValue() as Exclude<BatteryTestFailReason, '%future added value'>];
        }),
    exportValue: ({ value }) => FailReasonMap[value as Exclude<BatteryTestFailReason, '%future added value'>],
    meta: {
        filterable: true,
    },
});
export const StringCountColumn = columnHelper.display({
    id: TestResultTableColumn.BatteryStringCount,
    header: 'String Count',
    exportHeader: 'String Count',
    cell: ({ row }) => row.original.batteryStrings.length,
    exportValue: ({ row }) => row.batteryStrings.length,
    meta: {
        filterable: true,
    },
});
export const TemperatureMetricsColumn = columnHelper.accessor('averageTemperature', {
    id: TestResultTableColumn.TemperatureMetrics,
    header: 'Average Temperature',
    exportHeader: 'Average Temperature (°C)',
    cell: ({ cell }) =>
        renderMetricCell(() => {
            if (cell.getValue() == null) {
                return;
            }

            return `${numberToLocaleString(cell.getValue() as number)} °C`;
        }),
    meta: {
        filterable: true,
    },
});
export const CurrentMetricsColumn = columnHelper.accessor('averageCurrent', {
    id: TestResultTableColumn.CurrentMetrics,
    header: 'Average Current',
    exportHeader: 'Average Current (A)',
    cell: ({ cell }) =>
        renderMetricCell(() => {
            if (cell.getValue() == null) {
                return;
            }

            return `${numberToLocaleString(cell.getValue() as number)}A`;
        }),
    meta: {
        filterable: true,
    },
});
export const VoltageMetricsColumn = columnHelper.accessor('finalVoltage', {
    id: TestResultTableColumn.VoltageMetrics,
    header: 'End Voltage',
    exportHeader: 'End Voltage (V)',
    cell: ({ cell }) =>
        renderMetricCell(() => {
            if (cell.getValue() == null) {
                return;
            }

            return `${numberToLocaleString(cell.getValue() as number)}V`;
        }),
    meta: {
        filterable: true,
    },
});
export const DischargeeMetricsColumn = columnHelper.accessor('discharged', {
    id: TestResultTableColumn.DischargedMetrics,
    header: 'Total Discharged',
    exportHeader: 'Total Discharged (Ah)',
    cell: ({ cell }) =>
        renderMetricCell(() => {
            if (cell.getValue() == null) {
                return;
            }

            return `${numberToLocaleString(cell.getValue() as number)}Ah`;
        }),
    meta: {
        filterable: true,
    },
});

export const EstimatedStateOfHealth = columnHelper.accessor('estimatedStateOfHealth', {
    id: TestResultTableColumn.EstimatedStateOfHealth,
    header: 'Estimated State of Health',
    exportHeader: 'Estimated SoH',
    cell: ({ cell }) =>
        renderMetricCell(() => {
            const value = cell.getValue();
            if (value == null) {
                return;
            }

            if (value.reason || value.reasonDescription) {
                return;
            }

            let minimumStateOfHealth: number | undefined;

            if (cell.row.original?.batteryTypes?.length > 0) {
                minimumStateOfHealth = cell.row.original?.batteryTypes[0]?.minimumStateOfHealth;
            }

            let status: StatusType = 'unknown';

            if (minimumStateOfHealth != null) {
                if (value.confidenceInterval) {
                    const lower = value.confidenceInterval.lower;
                    const upper = value.confidenceInterval.upper;

                    if (upper < minimumStateOfHealth) {
                        status = 'bad';
                    } else if (lower >= minimumStateOfHealth) {
                        status = 'good';
                    } else {
                        status = 'warning';
                    }
                } else if (value.value != null) {
                    if (value.value < minimumStateOfHealth) {
                        status = 'bad';
                    } else if (value.value >= minimumStateOfHealth) {
                        status = 'good';
                    }
                }
            }

            const formattedValue = renderEstimatedValue(value, '%');
            if (formattedValue == null) {
                return;
            }
            return <span className={StatusColorMap[status]}>{formattedValue}</span>;
        }),
    exportValue: ({ value }) => {
        if (value == null) {
            return '';
        }

        if (value.reason || value.reasonDescription) {
            return '';
        }

        return renderEstimatedValue(value, '') ?? '';
    },
    meta: {
        filterable: true,
        sortable: true,
    },
});

export const EstimatedCapacity = columnHelper.accessor('estimatedCapacity', {
    id: TestResultTableColumn.EstimatedCapacity,
    header: 'Estimated Capacity',
    exportHeader: 'Estimated Capacity (Ah)',
    cell: ({ cell }) =>
        renderMetricCell(() => {
            const value = cell.getValue();
            if (value == null) {
                return;
            }

            if (value.reason || value.reasonDescription) {
                return;
            }

            return renderEstimatedValue(value, 'Ah');
        }),
    exportValue: ({ value }) => {
        if (value == null) {
            return '';
        }

        if (value.reason || value.reasonDescription) {
            return '';
        }

        return renderEstimatedValue(value, '') ?? '';
    },
    meta: {
        filterable: true,
        sortable: true,
    },
});

export const EstimatedReserveTime = columnHelper.accessor('estimatedReserveTime', {
    id: TestResultTableColumn.EstimatedReserveTime,
    header: 'Estimated Reserve Time',
    exportHeader: 'Estimated Reserve Time (min)',
    cell: ({ cell }) =>
        renderMetricCell(() => {
            const value = cell.getValue();
            if (value == null) {
                return;
            }

            if (value.reason || value.reasonDescription) {
                return;
            }

            let reserveTime = '';
            let reserveTimeStatus: StatusType = 'unknown';

            const configuredReserveTime = cell.row.original?.device?.battery?.reserveTime;

            if (value.confidenceInterval) {
                const lower = value.confidenceInterval.lower;
                const upper = value.confidenceInterval.upper;

                reserveTime = `${formatMinutesAsHoursAndMinutes(lower, true)} - ${formatMinutesAsHoursAndMinutes(
                    upper,
                    true
                )}`;

                if (configuredReserveTime != null) {
                    if (upper < configuredReserveTime) {
                        reserveTimeStatus = 'bad';
                    } else if (lower >= configuredReserveTime) {
                        reserveTimeStatus = 'good';
                    } else {
                        reserveTimeStatus = 'warning';
                    }
                }
            } else if (value.value != null) {
                let prefix = '';
                if (value.type === 'Minimum') {
                    prefix = 'Above ';
                } else if (value.type === 'Maximum') {
                    prefix = 'Below ';
                }

                reserveTime = `${prefix}${formatMinutesAsHoursAndMinutes(value.value, true)}`;

                if (configuredReserveTime != null) {
                    if (value.value < configuredReserveTime) {
                        reserveTimeStatus = 'bad';
                    } else {
                        reserveTimeStatus = 'good';
                    }
                } else {
                    return;
                }
            }

            return <span className={StatusColorMap[reserveTimeStatus]}>{reserveTime}</span>;
        }),
    exportValue: ({ value }) => {
        if (value == null) {
            return '';
        }

        if (value.reason || value.reasonDescription) {
            return '';
        }

        return renderEstimatedValue(value, '') ?? '';
    },
    meta: {
        filterable: true,
        sortable: true,
    },
});

const TestNameColumn = columnHelper.accessor('name', {
    id: TestResultTableColumn.Name,
    header: 'Test Name',
    exportHeader: 'Test Name',
    meta: {
        filterable: false,
        sortable: false,
        maxWidth: '8rem',
    },
});

export const BaseTableColumns = [
    DeviceNameColumn,
    StateColumn,
    RunTimeColumn,
    CauseColumn,
    StartTimeColumn,
] as ColumnWithId<TestResultTableColumn, TestResult>[];

export const AllTableColumns = [
    ...BaseTableColumns,
    FailReasonColumn,
    StringCountColumn,
    TemperatureMetricsColumn,
    CurrentMetricsColumn,
    VoltageMetricsColumn,
    DischargeeMetricsColumn,
    EstimatedStateOfHealth,
    EstimatedCapacity,
    EstimatedReserveTime,
    TestNameColumn,
] as ColumnWithId<TestResultTableColumn, TestResult>[];
