/**
 * @generated SignedSource<<d964ad603bb17c20ae35eaa5c0f4c2cd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type BatteryTestState = "Aborted" | "Analyzing" | "Failed" | "Finalizing" | "InProgress" | "Inconclusive" | "Passed" | "Scheduled" | "SmartStart" | "Waiting" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ViewBatteryTestResultContent_test$data = {
  readonly abortedTime: string | null;
  readonly commencedTime: string | null;
  readonly companion: {
    readonly device: {
      readonly id: string;
      readonly name: string;
      readonly type: {
        readonly displayName: string;
      };
    };
    readonly test: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"DualPlaneResult_test">;
    };
  } | null;
  readonly completedTime: string | null;
  readonly device: {
    readonly id: string;
    readonly name: string;
    readonly site: {
      readonly address: {
        readonly address: string | null;
        readonly state: string;
      };
      readonly id: string;
      readonly name: string;
    };
    readonly type: {
      readonly displayName: string;
    };
  };
  readonly id: string;
  readonly state: BatteryTestState;
  readonly task: {
    readonly id: string;
    readonly name: string | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"ViewBatteryTestResultCard_test">;
  readonly " $fragmentType": "ViewBatteryTestResultContent_test";
};
export type ViewBatteryTestResultContent_test$key = {
  readonly " $data"?: ViewBatteryTestResultContent_test$data;
  readonly " $fragmentSpreads": FragmentRefs<"ViewBatteryTestResultContent_test">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "DeviceType",
  "kind": "LinkedField",
  "name": "type",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ViewBatteryTestResultContent_test",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completedTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "commencedTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "abortedTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BatteryTest",
      "kind": "LinkedField",
      "name": "task",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BatteryTestResultCompanion",
      "kind": "LinkedField",
      "name": "companion",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Device",
          "kind": "LinkedField",
          "name": "device",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "DeviceBatteryTestResults",
          "kind": "LinkedField",
          "name": "test",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "DualPlaneResult_test"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Device",
      "kind": "LinkedField",
      "name": "device",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Site",
          "kind": "LinkedField",
          "name": "site",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Address",
              "kind": "LinkedField",
              "name": "address",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "address",
                  "storageKey": null
                },
                (v1/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ViewBatteryTestResultCard_test"
    }
  ],
  "type": "DeviceBatteryTestResults",
  "abstractKey": null
};
})();

(node as any).hash = "003c737d364565ccd8a5440031e6e6b8";

export default node;
