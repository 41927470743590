import React, { FC } from 'react';
import { useFragment } from 'react-relay';
import { generatePath } from 'react-router-dom';

import {
    DropletIcon,
    GeneratorIcon,
    HistoryItem,
    History as HistoryLine,
    Link,
    Menu,
    MetricTile,
    PowerIcon,
    WatchIcon,
    useExtendedNavigate,
} from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { useUserPermissions } from 'lib/auth';
import { getDateTimeFormat } from 'lib/dateFormatter';
import { createHistoryItem } from 'lib/history';
import { MenuItemGroup } from 'lib/menu';
import { numberToLocaleString } from 'lib/numberFormatters';
import { Paths } from 'lib/routes';
import { formatGeneratorState } from 'lib/textFormatters';
import { DateTime, Duration } from 'luxon';
import { makeLinkToMetric } from 'views/explore/lib/link';
import { Operation } from 'views/explore/types';

import { MetricInfo, getMetricInfo } from './DeviceOverview';
import { GeneratorOverview_device$key } from './__generated__/GeneratorOverview_device.graphql';
import { GeneratorOverview_settings$key } from './__generated__/GeneratorOverview_settings.graphql';

export interface GeneratorOverviewProps {
    generator: GeneratorOverview_device$key;
    generatorId: string;
    settings: GeneratorOverview_settings$key;
}

export const GeneratorOverview: FC<GeneratorOverviewProps> = ({ generator, generatorId, settings }) => {
    const { hasAssetsWrite, hasAssetsRead, hasTasksRead } = useUserPermissions();
    const navigate = useExtendedNavigate();
    const data = useFragment(GeneratorFragment, generator);
    const generatorSettings = useFragment(SetttingsFragment, settings);

    const gateway = data.connectionSettings.protocols?.at(0)?.gateway;
    const generatorStatus = formatGeneratorState(data.generatorMetrics.latestState);

    let generatorStatusSimplified: 'Idle' | 'Running' | 'Unknown' = 'Unknown';
    if (data.generatorMetrics.latestState === 'Idle') {
        generatorStatusSimplified = data.generatorMetrics.latestState;
    } else if (data.generatorMetrics.latestState) {
        generatorStatusSimplified = 'Running';
    } else {
        generatorStatusSimplified = 'Unknown';
    }

    const historyItems: HistoryItem[] = data.activityLogs.data.map(log =>
        createHistoryItem(log, hasAssetsRead, hasTasksRead)
    );
    const navigateToHistoryPage = () => {
        navigate({
            pathname: Paths.SiteViewViewSiteDevicePage,
            params: {
                siteId: data.site.id,
                deviceId: generatorId,
                page: 'history',
            },
        });
    };

    let lowFuelLevelThresholdPercent = generatorSettings.generator.lowFuelLevelThresholdPercent; // fallback value (global setting)
    if (data.generatorSettings?.lowFuelLevelThresholdPercent != null) {
        lowFuelLevelThresholdPercent = data.generatorSettings.lowFuelLevelThresholdPercent; // device specific setting
    }
    const timeSinceLastRunThreshold = Duration.fromISO(generatorSettings.generator.timeSinceLastRunThreshold);

    const isFuelLevelBelowThreshold =
        data.fuelTankMetrics.latestLevel !== null && data.fuelTankMetrics.latestLevel < lowFuelLevelThresholdPercent;

    // Shows the 'Time since last run' or 'Time since running' depending on the latest state
    let generatorRunMetricTile = <></>;
    if (data.generatorMetrics.latestState === 'Idle') {
        // generator is not running
        const lastRunDateTime = data.generatorMetrics.latestRunningTime;
        const durationDiff = lastRunDateTime ? DateTime.now().diff(DateTime.fromISO(lastRunDateTime)) : null;
        const metricValueAndUnit = getMetricDurationInfo(data.health === 'Offline' ? undefined : durationDiff);

        const lastRunDateTimeFormatted = lastRunDateTime ? getDateTimeFormat(lastRunDateTime) : 'Never';

        const isTimeSinceLastRunAboveThreshold = durationDiff ? durationDiff > timeSinceLastRunThreshold : false;

        generatorRunMetricTile = (
            <MetricTile
                icon={<WatchIcon />}
                title='Time since last run'
                subtitle={
                    data.health === 'Offline'
                        ? 'Device offline'
                        : isTimeSinceLastRunAboveThreshold
                          ? 'Threshold Exceeded'
                          : 'OK'
                }
                subtitleColor={data.health === 'Offline' || isTimeSinceLastRunAboveThreshold ? 'coral' : 'pine'}
                metricLabel={data.health === 'Offline' ? undefined : lastRunDateTimeFormatted}
                {...metricValueAndUnit}
            />
        );
    } else {
        // generator is running
        const startRunDateTime = data.generatorMetrics.latestTripRunTime
            ? DateTime.now().minus({ hours: data.generatorMetrics.latestTripRunTime })
            : null;
        const durationDiff = startRunDateTime?.diffNow().negate();
        const metricValueAndUnit = getMetricDurationInfo(data.health === 'Offline' ? undefined : durationDiff);

        const startRunDateTimeFormatted = startRunDateTime ? getDateTimeFormat(startRunDateTime.toJSDate()) : undefined;

        generatorRunMetricTile = (
            <MetricTile
                icon={<WatchIcon />}
                title='Time running'
                subtitle={data.health === 'Offline' ? 'Device offline' : 'OK'}
                subtitleColor={data.health === 'Offline' ? 'coral' : 'pine'}
                metricLabel={data.health === 'Offline' ? undefined : startRunDateTimeFormatted}
                {...metricValueAndUnit}
            />
        );
    }

    return (
        <div>
            <div className='grid grid-cols-2 gap-2'>
                <div className='mb-2'>
                    <div className='text-xs'>
                        <div data-testid='gateway_device'>
                            <span className='font-light'>Gateway Device: </span>
                            <Link
                                className='font-bold hover:underline'
                                to={generatePath(Paths.EditDevice, { id: gateway?.id })}
                            >
                                {gateway?.name ?? '-'}
                            </Link>
                        </div>
                        <div data-testid='gateway_ip_address'>
                            <span className='font-light'>Gateway IP: </span>
                            <Link
                                className='font-bold hover:underline'
                                to={generatePath(Paths.EditDevice, { id: gateway?.id })}
                            >
                                {gateway?.connectionSettings.addresses.at(0) ?? '-'}
                            </Link>
                        </div>
                    </div>
                </div>
                <div className='mb-2'>
                    <div className='float-right'>
                        <Menu
                            id={`device-menu-${generatorId}`}
                            groups={[{ key: MenuItemGroup.Assets, title: MenuItemGroup.Assets }]}
                            menuItems={[
                                {
                                    name: hasAssetsWrite ? 'Edit generator' : 'View generator',
                                    disabled: !hasAssetsRead,
                                    onClick: () =>
                                        navigate({ pathname: Paths.EditDevice, params: { id: generatorId } }),
                                    group: MenuItemGroup.Assets,
                                },
                                {
                                    name: hasAssetsWrite ? 'Edit gateway' : 'View gateway',
                                    disabled: !hasAssetsRead || !gateway,
                                    onClick: () => {
                                        if (!gateway) {
                                            return;
                                        }

                                        navigate({
                                            pathname: Paths.EditDevice,
                                            params: {
                                                id: gateway.id,
                                            },
                                        });
                                    },
                                    group: MenuItemGroup.Assets,
                                },
                            ]}
                            variant='small'
                        />
                    </div>
                    <div className='text-xs'>
                        <div data-testid='serial_number'>
                            <span className='font-light'>Serial number: </span>
                            <span className='font-normal'>{data.serialNumber || '-'}</span>
                        </div>
                        <div data-testid='model_number'>
                            <span className='font-light'>Model number: </span>
                            <span className='font-normal'>{data.model || '-'}</span>
                        </div>
                    </div>
                </div>
                <MetricTile
                    icon={<GeneratorIcon />}
                    title='Current Status'
                    subtitle={data.health === 'Offline' ? 'Device offline' : generatorStatus}
                    subtitleColor={data.health === 'Offline' ? 'coral' : 'pine'}
                    metricValue={data.health === 'Offline' ? undefined : generatorStatusSimplified}
                    metricLabel={
                        data.generatorMetrics.latestOperationMode
                            ? `${data.generatorMetrics.latestOperationMode} mode`
                            : undefined
                    }
                />
                <MetricTile
                    icon={<DropletIcon />}
                    title='Fuel Level'
                    subtitle={
                        data.health === 'Offline'
                            ? 'Device offline'
                            : isFuelLevelBelowThreshold
                              ? 'Low Fuel Level'
                              : 'OK'
                    }
                    subtitleColor={data.health === 'Offline' || isFuelLevelBelowThreshold ? 'coral' : 'pine'}
                    metricValue={data.health === 'Offline' ? undefined : data.fuelTankMetrics.latestLevel ?? undefined}
                    metricUnit='%'
                    metricLabel={
                        data.fuelTankMetrics.capacity !== null
                            ? `out of ${numberToLocaleString(data.fuelTankMetrics.capacity, 0)} litres`
                            : 'Current level'
                    }
                    metricLink={makeLinkToMetric(generatorId, {
                        metric: 'FuelTankLevel',
                        op: Operation.Average,
                    })}
                />
                <MetricTile
                    icon={<PowerIcon />}
                    title='Power Output'
                    subtitle={data.health === 'Offline' ? 'Device offline' : 'OK'}
                    subtitleColor={data.health === 'Offline' ? 'coral' : 'pine'}
                    {...getMetricInfo(
                        data.health === 'Offline' ? null : data.generatorMetrics.output.latestApparentPower,
                        'VA',
                        'kVA'
                    )}
                    metricLabel='Apparent power'
                    metricLink={makeLinkToMetric(generatorId, {
                        metric: 'GeneratorOutputTotalApparentPower',
                        op: Operation.Average,
                    })}
                />
                {generatorRunMetricTile}
            </div>
            <div className='pt-4'>
                <HistoryLine
                    title='History'
                    lineStyle='short'
                    itemGroups={[{ items: historyItems }]}
                    moreItems={data.activityLogs.hasMore}
                    loadMoreCallback={navigateToHistoryPage}
                />
            </div>
        </div>
    );
};

const SetttingsFragment = graphql`
    fragment GeneratorOverview_settings on Settings {
        generator {
            lowFuelLevelThresholdPercent
            timeSinceLastRunThreshold
        }
    }
`;

const GeneratorFragment = graphql`
    fragment GeneratorOverview_device on Device {
        site {
            id
        }
        name
        serialNumber
        model
        lastUpdate
        health
        type {
            displayName
        }

        connectionSettings {
            protocols {
                ... on ProtocolGateway {
                    gateway {
                        id
                        name
                        connectionSettings {
                            addresses
                        }
                    }
                }
            }
        }

        generatorSettings {
            lowFuelLevelThresholdPercent
        }

        generatorMetrics {
            latestState
            latestOperationMode

            output {
                latestApparentPower
            }

            latestRunningTime
            latestEngineRunTime
            latestTripRunTime
        }
        fuelTankMetrics {
            latestLevel
            capacity
        }

        activityLogs(
            types: [
                DeviceAdd
                DeviceEdit
                AlertSeverityCritical
                AlertSeverityMajor
                GridEventStatusOffline
                GridEventStatusHigh
                BatteryTest
            ]
            limit: 5
        ) {
            hasMore
            total
            data {
                source
                type
                timestamp
                user {
                    username
                    name
                }
                changes {
                    field
                    oldValue
                    newValue
                }
                link {
                    __typename
                    ... on Device {
                        id
                        name
                    }
                    ... on Alert {
                        severity
                        category
                        message
                        isActive
                        device {
                            id
                            name
                        }
                    }
                }
            }
        }
    }
`;

/**
 * Converts duration to a number and a unit representation (fixed to whole numbers).
 * The conditions are as below:
 * - If duration is less than 1 hour, show minutes
 * - If duration is less than 1 day, show hours
 * - If duration is more than 1 day, show days
 */
function getMetricDurationInfo(value: Duration | undefined | null): MetricInfo {
    if (value === null || value === undefined) {
        return {
            metricValue: undefined,
            metricUnit: 'hours', // default to hours
        };
    }

    if (value.as('hours') < 1) {
        return {
            metricValue: Math.trunc(value.as('minutes')),
            metricUnit: 'minutes',
        };
    } else if (value.as('days') < 1) {
        return {
            metricValue: Math.trunc(value.as('hours')),
            metricUnit: 'hours',
        };
    } else {
        return {
            metricValue: Math.trunc(value.as('days')),
            metricUnit: 'days',
        };
    }
}
