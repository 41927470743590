/**
 * @generated SignedSource<<8cb882e56ec1f313602fe6f0b6ba39eb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
export type BatteryTestState = "Aborted" | "Analyzing" | "Failed" | "Finalizing" | "InProgress" | "Inconclusive" | "Passed" | "Scheduled" | "SmartStart" | "Waiting" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DataDisplayCombined_test$data = {
  readonly abortedTime: string | null;
  readonly aggregatedCurrent: ReadonlyArray<{
    readonly average: number | null;
    readonly offset: string;
    readonly timestamp: string;
  }> | null;
  readonly aggregatedPower: ReadonlyArray<{
    readonly average: number | null;
    readonly offset: string;
    readonly timestamp: string;
  }> | null;
  readonly aggregatedTemperature: ReadonlyArray<{
    readonly average: number | null;
    readonly offset: string;
    readonly timestamp: string;
  }> | null;
  readonly aggregatedVoltage: ReadonlyArray<{
    readonly average: number | null;
    readonly offset: string;
    readonly timestamp: string;
  }> | null;
  readonly commencedTime: string | null;
  readonly completedTime: string | null;
  readonly coupDeFouet: {
    readonly dip: number;
    readonly float: number;
    readonly plateauOffset: string;
    readonly plateauVoltage: number;
    readonly troughOffset: string;
    readonly troughVoltage: number;
  } | null;
  readonly state: BatteryTestState;
  readonly " $fragmentType": "DataDisplayCombined_test";
};
export type DataDisplayCombined_test$key = {
  readonly " $data"?: DataDisplayCombined_test$data;
  readonly " $fragmentSpreads": FragmentRefs<"DataDisplayCombined_test">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "DataDisplayCombined_test"
};

(node as any).hash = "9f3d76b86807a82c21ae46f66c8f9aed";

export default node;
