/**
 * @generated SignedSource<<a57b51e651a3ba82ec2e63307c117f0b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type GeneratorRunReportState = "Analysing" | "Completed" | "Error" | "Finishing" | "Running" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type RunReportResultDisplay_data$data = {
  readonly fuelConsumptionRate: {
    readonly average: number | null;
    readonly latest: number | null;
    readonly max: number | null;
    readonly min: number | null;
  } | null;
  readonly fuelConsumptionRateOverTime: ReadonlyArray<{
    readonly offset: string;
    readonly value: number | null;
  }> | null;
  readonly fuelLevel: {
    readonly average: number | null;
    readonly latest: number | null;
    readonly max: number | null;
    readonly min: number | null;
  } | null;
  readonly fuelLevelOverTime: ReadonlyArray<{
    readonly offset: string;
    readonly value: number | null;
  }> | null;
  readonly id: string;
  readonly state: GeneratorRunReportState;
  readonly " $fragmentSpreads": FragmentRefs<"Events_data" | "FuelStats_data" | "LoadChart_info" | "MetricsView_data" | "VitalsTable_info">;
  readonly " $fragmentType": "RunReportResultDisplay_data";
};
export type RunReportResultDisplay_data$key = {
  readonly " $data"?: RunReportResultDisplay_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"RunReportResultDisplay_data">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Literal",
  "name": "unit",
  "value": "LiterPerHour"
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "min",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "max",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "average",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "latest",
    "storageKey": null
  }
],
v2 = {
  "kind": "Variable",
  "name": "points",
  "variableName": "chartPoints"
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "offset",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "chartPoints"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "RunReportResultDisplay_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        (v0/*: any*/)
      ],
      "concreteType": "ReportValueStats",
      "kind": "LinkedField",
      "name": "fuelConsumptionRate",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": "fuelConsumptionRate(unit:\"LiterPerHour\")"
    },
    {
      "alias": null,
      "args": [
        (v2/*: any*/),
        (v0/*: any*/)
      ],
      "concreteType": "TimestampMetricWithOffset",
      "kind": "LinkedField",
      "name": "fuelConsumptionRateOverTime",
      "plural": true,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ReportValueStats",
      "kind": "LinkedField",
      "name": "fuelLevel",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        (v2/*: any*/),
        {
          "kind": "Literal",
          "name": "unit",
          "value": "Liter"
        }
      ],
      "concreteType": "TimestampMetricWithOffset",
      "kind": "LinkedField",
      "name": "fuelLevelOverTime",
      "plural": true,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MetricsView_data"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LoadChart_info"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "VitalsTable_info"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FuelStats_data"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Events_data"
    }
  ],
  "type": "GeneratorRunReport",
  "abstractKey": null
};
})();

(node as any).hash = "5f316c5aac68debe3274e39d8901053f";

export default node;
