/**
 * @generated SignedSource<<b8958006b169aaddf5eab3abd6a07277>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MetricsViewCombined_test$data = {
  readonly averageCurrent: number | null;
  readonly averageTemperature: number | null;
  readonly device: {
    readonly name: string;
  };
  readonly discharged: number | null;
  readonly finalVoltage: number | null;
  readonly " $fragmentSpreads": FragmentRefs<"ResultView_test">;
  readonly " $fragmentType": "MetricsViewCombined_test";
};
export type MetricsViewCombined_test$key = {
  readonly " $data"?: MetricsViewCombined_test$data;
  readonly " $fragmentSpreads": FragmentRefs<"MetricsViewCombined_test">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MetricsViewCombined_test",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "unit",
          "value": "Amps"
        }
      ],
      "kind": "ScalarField",
      "name": "averageCurrent",
      "storageKey": "averageCurrent(unit:\"Amps\")"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "unit",
          "value": "Celsius"
        }
      ],
      "kind": "ScalarField",
      "name": "averageTemperature",
      "storageKey": "averageTemperature(unit:\"Celsius\")"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "unit",
          "value": "AmpHour"
        }
      ],
      "kind": "ScalarField",
      "name": "discharged",
      "storageKey": "discharged(unit:\"AmpHour\")"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "unit",
          "value": "Volts"
        }
      ],
      "kind": "ScalarField",
      "name": "finalVoltage",
      "storageKey": "finalVoltage(unit:\"Volts\")"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Device",
      "kind": "LinkedField",
      "name": "device",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ResultView_test"
    }
  ],
  "type": "DeviceBatteryTestResults",
  "abstractKey": null
};

(node as any).hash = "81f02b593500a4d3e1fc149d798f7f5e";

export default node;
