import React, { FC } from 'react';

import { FilterRange } from 'components/FilterRange';
import { CommonFilterProps } from 'filters/common';

import { GeneratorRunReportStatsFilter } from '../types';

export const GeneratorReportRpmAvgFilterUI: FC<CommonFilterProps<GeneratorRunReportStatsFilter | null>> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
}) => {
    return (
        <FilterRange
            title='Filter by average RPM'
            onSubmit={onSubmit}
            onClose={onClose}
            current={current}
            showBack={hasPrevious}
            onClearOrBack={onClearOrBack}
            countLabel={['Min', 'Max']}
            hideDistribution
            distributionRange={null}
        />
    );
};

export const GeneratorReportRpmMinFilterUI: FC<CommonFilterProps<GeneratorRunReportStatsFilter | null>> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
}) => {
    return (
        <FilterRange
            title='Filter by minimum RPM'
            onSubmit={onSubmit}
            onClose={onClose}
            current={current}
            showBack={hasPrevious}
            onClearOrBack={onClearOrBack}
            countLabel={['Min', 'Max']}
            hideDistribution
            distributionRange={null}
        />
    );
};

export const GeneratorReportRpmMaxFilterUI: FC<CommonFilterProps<GeneratorRunReportStatsFilter | null>> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
}) => {
    return (
        <FilterRange
            title='Filter by maximum RPM'
            onSubmit={onSubmit}
            onClose={onClose}
            current={current}
            showBack={hasPrevious}
            onClearOrBack={onClearOrBack}
            countLabel={['Min', 'Max']}
            hideDistribution
            distributionRange={null}
        />
    );
};

export const GeneratorReportCoolantTemperatureAvgFilterUI: FC<
    CommonFilterProps<GeneratorRunReportStatsFilter | null>
> = ({ current, onClearOrBack, onClose, onSubmit, hasPrevious }) => {
    return (
        <FilterRange
            title='Filter by average coolant temperature (°C)'
            onSubmit={onSubmit}
            onClose={onClose}
            current={current}
            showBack={hasPrevious}
            onClearOrBack={onClearOrBack}
            countLabel={['Min', 'Max']}
            hideDistribution
            distributionRange={null}
        />
    );
};

export const GeneratorReportCoolantTemperatureMinFilterUI: FC<
    CommonFilterProps<GeneratorRunReportStatsFilter | null>
> = ({ current, onClearOrBack, onClose, onSubmit, hasPrevious }) => {
    return (
        <FilterRange
            title='Filter by minimum coolant temperature (°C)'
            onSubmit={onSubmit}
            onClose={onClose}
            current={current}
            showBack={hasPrevious}
            onClearOrBack={onClearOrBack}
            countLabel={['Min', 'Max']}
            hideDistribution
            distributionRange={null}
        />
    );
};

export const GeneratorReportCoolantTemperatureMaxFilterUI: FC<
    CommonFilterProps<GeneratorRunReportStatsFilter | null>
> = ({ current, onClearOrBack, onClose, onSubmit, hasPrevious }) => {
    return (
        <FilterRange
            title='Filter by maximum coolant temperature (°C)'
            onSubmit={onSubmit}
            onClose={onClose}
            current={current}
            showBack={hasPrevious}
            onClearOrBack={onClearOrBack}
            countLabel={['Min', 'Max']}
            hideDistribution
            distributionRange={null}
        />
    );
};

export const GeneratorReportCoolantPressureAvgFilterUI: FC<CommonFilterProps<GeneratorRunReportStatsFilter | null>> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
}) => {
    return (
        <FilterRange
            title='Filter by average coolant pressure (psi)'
            onSubmit={onSubmit}
            onClose={onClose}
            current={current}
            showBack={hasPrevious}
            onClearOrBack={onClearOrBack}
            countLabel={['Min', 'Max']}
            hideDistribution
            distributionRange={null}
        />
    );
};

export const GeneratorReportCoolantPressureMinFilterUI: FC<CommonFilterProps<GeneratorRunReportStatsFilter | null>> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
}) => {
    return (
        <FilterRange
            title='Filter by minimum coolant pressure (psi)'
            onSubmit={onSubmit}
            onClose={onClose}
            current={current}
            showBack={hasPrevious}
            onClearOrBack={onClearOrBack}
            countLabel={['Min', 'Max']}
            hideDistribution
            distributionRange={null}
        />
    );
};

export const GeneratorReportCoolantPressureMaxFilterUI: FC<CommonFilterProps<GeneratorRunReportStatsFilter | null>> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
}) => {
    return (
        <FilterRange
            title='Filter by maximum coolant pressure (psi)'
            onSubmit={onSubmit}
            onClose={onClose}
            current={current}
            showBack={hasPrevious}
            onClearOrBack={onClearOrBack}
            countLabel={['Min', 'Max']}
            hideDistribution
            distributionRange={null}
        />
    );
};

export const GeneratorReportOilTemperatureAvgFilterUI: FC<CommonFilterProps<GeneratorRunReportStatsFilter | null>> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
}) => {
    return (
        <FilterRange
            title='Filter by average oil temperature (°C)'
            onSubmit={onSubmit}
            onClose={onClose}
            current={current}
            showBack={hasPrevious}
            onClearOrBack={onClearOrBack}
            countLabel={['Min', 'Max']}
            hideDistribution
            distributionRange={null}
        />
    );
};

export const GeneratorReportOilTemperatureMinFilterUI: FC<CommonFilterProps<GeneratorRunReportStatsFilter | null>> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
}) => {
    return (
        <FilterRange
            title='Filter by minimum oil temperature (°C)'
            onSubmit={onSubmit}
            onClose={onClose}
            current={current}
            showBack={hasPrevious}
            onClearOrBack={onClearOrBack}
            countLabel={['Min', 'Max']}
            hideDistribution
            distributionRange={null}
        />
    );
};

export const GeneratorReportOilTemperatureMaxFilterUI: FC<CommonFilterProps<GeneratorRunReportStatsFilter | null>> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
}) => {
    return (
        <FilterRange
            title='Filter by maximum oil temperature (°C)'
            onSubmit={onSubmit}
            onClose={onClose}
            current={current}
            showBack={hasPrevious}
            onClearOrBack={onClearOrBack}
            countLabel={['Min', 'Max']}
            hideDistribution
            distributionRange={null}
        />
    );
};

export const GeneratorReportOilPressureAvgFilterUI: FC<CommonFilterProps<GeneratorRunReportStatsFilter | null>> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
}) => {
    return (
        <FilterRange
            title='Filter by average oil pressure (psi)'
            onSubmit={onSubmit}
            onClose={onClose}
            current={current}
            showBack={hasPrevious}
            onClearOrBack={onClearOrBack}
            countLabel={['Min', 'Max']}
            hideDistribution
            distributionRange={null}
        />
    );
};

export const GeneratorReportOilPressureMinFilterUI: FC<CommonFilterProps<GeneratorRunReportStatsFilter | null>> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
}) => {
    return (
        <FilterRange
            title='Filter by minimum oil pressure (psi)'
            onSubmit={onSubmit}
            onClose={onClose}
            current={current}
            showBack={hasPrevious}
            onClearOrBack={onClearOrBack}
            countLabel={['Min', 'Max']}
            hideDistribution
            distributionRange={null}
        />
    );
};

export const GeneratorReportOilPressureMaxFilterUI: FC<CommonFilterProps<GeneratorRunReportStatsFilter | null>> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
}) => {
    return (
        <FilterRange
            title='Filter by maximum oil pressure (psi)'
            onSubmit={onSubmit}
            onClose={onClose}
            current={current}
            showBack={hasPrevious}
            onClearOrBack={onClearOrBack}
            countLabel={['Min', 'Max']}
            hideDistribution
            distributionRange={null}
        />
    );
};

export const GeneratorReportFuelConsumptionRateAvgFilterUI: FC<
    CommonFilterProps<GeneratorRunReportStatsFilter | null>
> = ({ current, onClearOrBack, onClose, onSubmit, hasPrevious }) => (
    <FilterRange
        title='Filter by average fuel consumption rate (L/hr)'
        onSubmit={onSubmit}
        onClose={onClose}
        current={current}
        showBack={hasPrevious}
        onClearOrBack={onClearOrBack}
        countLabel={['Min', 'Max']}
        hideDistribution
        distributionRange={null}
    />
);

export const GeneratorReportFuelConsumptionRateMinFilterUI: FC<
    CommonFilterProps<GeneratorRunReportStatsFilter | null>
> = ({ current, onClearOrBack, onClose, onSubmit, hasPrevious }) => (
    <FilterRange
        title='Filter by minimum fuel consumption rate (L/hr)'
        onSubmit={onSubmit}
        onClose={onClose}
        current={current}
        showBack={hasPrevious}
        onClearOrBack={onClearOrBack}
        countLabel={['Min', 'Max']}
        hideDistribution
        distributionRange={null}
    />
);

export const GeneratorReportFuelConsumptionRateMaxFilterUI: FC<
    CommonFilterProps<GeneratorRunReportStatsFilter | null>
> = ({ current, onClearOrBack, onClose, onSubmit, hasPrevious }) => (
    <FilterRange
        title='Filter by maximum fuel consumption rate (L/hr)'
        onSubmit={onSubmit}
        onClose={onClose}
        current={current}
        showBack={hasPrevious}
        onClearOrBack={onClearOrBack}
        countLabel={['Min', 'Max']}
        hideDistribution
        distributionRange={null}
    />
);

export const GeneratorReportFuelLevelMinFilterUI: FC<CommonFilterProps<GeneratorRunReportStatsFilter | null>> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
}) => (
    <FilterRange
        title='Filter by minimum fuel level (%)'
        onSubmit={onSubmit}
        onClose={onClose}
        current={current}
        showBack={hasPrevious}
        onClearOrBack={onClearOrBack}
        countLabel={['Min', 'Max']}
        hideDistribution
        distributionRange={null}
    />
);

export const GeneratorReportFuelLevelMaxFilterUI: FC<CommonFilterProps<GeneratorRunReportStatsFilter | null>> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
}) => (
    <FilterRange
        title='Filter by maximum fuel level (%)'
        onSubmit={onSubmit}
        onClose={onClose}
        current={current}
        showBack={hasPrevious}
        onClearOrBack={onClearOrBack}
        countLabel={['Min', 'Max']}
        hideDistribution
        distributionRange={null}
    />
);

export const GeneratorReportFuelLevelFinishFilterUI: FC<CommonFilterProps<GeneratorRunReportStatsFilter | null>> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
}) => (
    <FilterRange
        title='Filter by fuel level at finish (%)'
        onSubmit={onSubmit}
        onClose={onClose}
        current={current}
        showBack={hasPrevious}
        onClearOrBack={onClearOrBack}
        countLabel={['Min', 'Max']}
        hideDistribution
        distributionRange={null}
    />
);

export const GeneratorReportFuelConsumedTotalFilterUI: FC<CommonFilterProps<GeneratorRunReportStatsFilter | null>> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
}) => (
    <FilterRange
        title='Filter by total fuel consumed (L)'
        onSubmit={onSubmit}
        onClose={onClose}
        current={current}
        showBack={hasPrevious}
        onClearOrBack={onClearOrBack}
        countLabel={['Min', 'Max']}
        hideDistribution
        distributionRange={null}
    />
);

export const GeneratorReportFuelRefuelledTotalFilterUI: FC<CommonFilterProps<GeneratorRunReportStatsFilter | null>> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
}) => (
    <FilterRange
        title='Filter by total fuel refuelled (L)'
        onSubmit={onSubmit}
        onClose={onClose}
        current={current}
        showBack={hasPrevious}
        onClearOrBack={onClearOrBack}
        countLabel={['Min', 'Max']}
        hideDistribution
        distributionRange={null}
    />
);

export const GeneratorReportOutputPowerAvgFilterUI: FC<CommonFilterProps<GeneratorRunReportStatsFilter | null>> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
}) => (
    <FilterRange
        title='Filter by average output power (W)'
        onSubmit={onSubmit}
        onClose={onClose}
        current={current}
        showBack={hasPrevious}
        onClearOrBack={onClearOrBack}
        countLabel={['Min', 'Max']}
        hideDistribution
        distributionRange={null}
    />
);

export const GeneratorReportOutputEnergyTotalFilterUI: FC<CommonFilterProps<GeneratorRunReportStatsFilter | null>> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
}) => (
    <FilterRange
        title='Filter by total output energy (Wh)'
        onSubmit={onSubmit}
        onClose={onClose}
        current={current}
        showBack={hasPrevious}
        onClearOrBack={onClearOrBack}
        countLabel={['Min', 'Max']}
        hideDistribution
        distributionRange={null}
    />
);
