/**
 * @generated SignedSource<<3da77ed45b2979c66c94254bb0a6a100>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeviceCategory = "Gateway" | "Generator" | "PowerController" | "%future added value";
export type DeviceHealth = "Critical" | "Degraded" | "Healthy" | "Offline" | "Unknown" | "%future added value";
export type RectifiersContentQuery$variables = {
  begin: string;
  end: string;
  id: string;
};
export type RectifiersContentQuery$data = {
  readonly device: {
    readonly dualPlaneCompanion: {
      readonly device: {
        readonly health: DeviceHealth | null;
        readonly id: string;
        readonly lastOnline: string | null;
        readonly lastUpdate: string | null;
        readonly name: string;
        readonly type: {
          readonly displayName: string;
        };
        readonly " $fragmentSpreads": FragmentRefs<"DeviceRectifiers_device">;
      } | null;
    } | null;
    readonly health: DeviceHealth | null;
    readonly id: string;
    readonly lastOnline: string | null;
    readonly lastUpdate: string | null;
    readonly name: string;
    readonly site: {
      readonly id: string;
      readonly powerControllers: {
        readonly data: ReadonlyArray<{
          readonly id: string;
        }>;
      };
    };
    readonly type: {
      readonly category: DeviceCategory;
      readonly displayName: string;
    };
    readonly " $fragmentSpreads": FragmentRefs<"DeviceRectifiers_device">;
  } | null;
};
export type RectifiersContentQuery = {
  response: RectifiersContentQuery$data;
  variables: RectifiersContentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "begin"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "end"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "health",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastUpdate",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastOnline",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "category",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "Site",
  "kind": "LinkedField",
  "name": "site",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    {
      "alias": "powerControllers",
      "args": [
        {
          "kind": "Literal",
          "name": "filters",
          "value": {
            "category": [
              "PowerController"
            ]
          }
        }
      ],
      "concreteType": "PaginatedDevices",
      "kind": "LinkedField",
      "name": "devices",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Device",
          "kind": "LinkedField",
          "name": "data",
          "plural": true,
          "selections": [
            (v4/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": "devices(filters:{\"category\":[\"PowerController\"]})"
    }
  ],
  "storageKey": null
},
v12 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "DeviceRectifiers_device"
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "latestTemperature",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "latestOutputCurrent",
  "storageKey": null
},
v15 = [
  {
    "kind": "Literal",
    "name": "unit",
    "value": "Watt"
  }
],
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "DeviceRectifier",
  "kind": "LinkedField",
  "name": "rectifier",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "from",
          "variableName": "begin"
        },
        {
          "kind": "Variable",
          "name": "to",
          "variableName": "end"
        }
      ],
      "concreteType": "RectifierUptime",
      "kind": "LinkedField",
      "name": "uptime",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "percentage",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DeviceRectifierMetrics",
      "kind": "LinkedField",
      "name": "metrics",
      "plural": false,
      "selections": [
        (v13/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "latestOutputVoltage",
          "storageKey": null
        },
        (v14/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v15/*: any*/),
      "kind": "ScalarField",
      "name": "totalCapacity",
      "storageKey": "totalCapacity(unit:\"Watt\")"
    },
    {
      "alias": null,
      "args": (v15/*: any*/),
      "kind": "ScalarField",
      "name": "freeCapacity",
      "storageKey": "freeCapacity(unit:\"Watt\")"
    },
    {
      "alias": null,
      "args": (v15/*: any*/),
      "kind": "ScalarField",
      "name": "usedCapacity",
      "storageKey": "usedCapacity(unit:\"Watt\")"
    },
    {
      "alias": null,
      "args": (v15/*: any*/),
      "kind": "ScalarField",
      "name": "offlineCapacity",
      "storageKey": "offlineCapacity(unit:\"Watt\")"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "capacityAtRiskWithHeadroom",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PaginatedRectifierModules",
      "kind": "LinkedField",
      "name": "modules",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RectifierModule",
          "kind": "LinkedField",
          "name": "data",
          "plural": true,
          "selections": [
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "label",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "RectifierModuleMetrics",
              "kind": "LinkedField",
              "name": "metrics",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                },
                (v13/*: any*/),
                (v14/*: any*/),
                {
                  "alias": null,
                  "args": (v15/*: any*/),
                  "kind": "ScalarField",
                  "name": "latestOutputPower",
                  "storageKey": "latestOutputPower(unit:\"Watt\")"
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "RectifierModuleSpecs",
              "kind": "LinkedField",
              "name": "specs",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "manufacturer",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "model",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "RectifierModuleOutputSpecs",
                  "kind": "LinkedField",
                  "name": "output",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": (v15/*: any*/),
                      "kind": "ScalarField",
                      "name": "maximumPower",
                      "storageKey": "maximumPower(unit:\"Watt\")"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "serialNumber",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "RectifiersContentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Device",
        "kind": "LinkedField",
        "name": "device",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "DeviceType",
            "kind": "LinkedField",
            "name": "type",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              (v10/*: any*/)
            ],
            "storageKey": null
          },
          (v11/*: any*/),
          (v12/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "DualPlaneCompanion",
            "kind": "LinkedField",
            "name": "dualPlaneCompanion",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Device",
                "kind": "LinkedField",
                "name": "device",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DeviceType",
                    "kind": "LinkedField",
                    "name": "type",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v12/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "RectifiersContentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Device",
        "kind": "LinkedField",
        "name": "device",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "DeviceType",
            "kind": "LinkedField",
            "name": "type",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              (v10/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v11/*: any*/),
          (v16/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "DualPlaneCompanion",
            "kind": "LinkedField",
            "name": "dualPlaneCompanion",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Device",
                "kind": "LinkedField",
                "name": "device",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DeviceType",
                    "kind": "LinkedField",
                    "name": "type",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v16/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c93d707c29f378bfcb8a1f9de80ed89c",
    "id": null,
    "metadata": {},
    "name": "RectifiersContentQuery",
    "operationKind": "query",
    "text": "query RectifiersContentQuery(\n  $id: ID!\n  $begin: Timestamp!\n  $end: Timestamp!\n) {\n  device(id: $id) {\n    id\n    name\n    health\n    lastUpdate\n    lastOnline\n    type {\n      displayName\n      category\n      id\n    }\n    site {\n      id\n      powerControllers: devices(filters: {category: [PowerController]}) {\n        data {\n          id\n        }\n      }\n    }\n    ...DeviceRectifiers_device\n    dualPlaneCompanion {\n      device {\n        id\n        name\n        health\n        lastUpdate\n        lastOnline\n        type {\n          displayName\n          id\n        }\n        ...DeviceRectifiers_device\n      }\n    }\n  }\n}\n\nfragment DeviceRectifiers_device on Device {\n  health\n  rectifier {\n    uptime(from: $begin, to: $end) {\n      percentage\n    }\n    metrics {\n      latestTemperature\n      latestOutputVoltage\n      latestOutputCurrent\n    }\n    totalCapacity(unit: Watt)\n    freeCapacity(unit: Watt)\n    usedCapacity(unit: Watt)\n    offlineCapacity(unit: Watt)\n    capacityAtRiskWithHeadroom\n    modules {\n      data {\n        id\n        ...ModuleTile_data\n        ...ModuleRow_data\n      }\n    }\n  }\n}\n\nfragment ModuleRow_data on RectifierModule {\n  id\n  label\n  specs {\n    manufacturer\n    model\n    output {\n      maximumPower(unit: Watt)\n    }\n  }\n  serialNumber\n  metrics {\n    status\n    latestTemperature\n    latestOutputCurrent\n    latestOutputPower(unit: Watt)\n  }\n}\n\nfragment ModuleTile_data on RectifierModule {\n  id\n  label\n  metrics {\n    status\n    latestTemperature\n    latestOutputCurrent\n  }\n}\n"
  }
};
})();

(node as any).hash = "53013974fabb6e4914e6cbca82d6802d";

export default node;
