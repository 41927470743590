import React, { FC } from 'react';
import { useFragment } from 'react-relay';

import { MetricDisplay } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { numberToLocaleString } from 'lib/numberFormatters';

import { MetricsViewCombinedLive_test$key } from './__generated__/MetricsViewCombinedLive_test.graphql';

const MetricsFragment = graphql`
    fragment MetricsViewCombinedLive_test on DeviceBatteryTestResults {
        device {
            battery {
                metrics {
                    originalCapacity(unit: AmpHour)
                    latestVoltage(unit: Volts)
                    latestCurrent(unit: Amps)
                    latestTemperature(unit: Celsius)
                    latestRemainingCapacity(unit: AmpHour)
                    latestUsedCapacity(unit: AmpHour)
                }
            }
        }
    }
`;

interface MetricsViewCombinedLiveProps {
    test1Ref: MetricsViewCombinedLive_test$key;
    test2Ref: MetricsViewCombinedLive_test$key;
}

export const MetricsViewCombinedLive: FC<MetricsViewCombinedLiveProps> = ({ test1Ref, test2Ref }) => {
    let capacity;
    let latestVoltage;
    let latestTemperature;
    let latestCurrent;
    let latestDoD;

    const { device: device1 } = useFragment<MetricsViewCombinedLive_test$key>(MetricsFragment, test1Ref);
    const { device: device2 } = useFragment<MetricsViewCombinedLive_test$key>(MetricsFragment, test2Ref);

    const device1Metrics = device1.battery.metrics;
    const device2Metrics = device2.battery.metrics;

    // lowest capacity
    if (device1Metrics.originalCapacity && device2Metrics.originalCapacity) {
        if (device1Metrics.latestRemainingCapacity != null && device2Metrics.latestRemainingCapacity != null) {
            if (device1Metrics.latestRemainingCapacity < device2Metrics.latestRemainingCapacity) {
                capacity = `${numberToLocaleString(device1Metrics.latestRemainingCapacity, 0)} / ${numberToLocaleString(
                    device1Metrics.originalCapacity,
                    0
                )}Ah`;
            } else {
                capacity = `${numberToLocaleString(device2Metrics.latestRemainingCapacity, 0)} / ${numberToLocaleString(
                    device2Metrics.originalCapacity,
                    0
                )}Ah`;
            }
        } else {
            if (device1Metrics.originalCapacity < device2Metrics.originalCapacity) {
                capacity = `- / ${numberToLocaleString(device1Metrics.originalCapacity, 0)}Ah`;
            } else {
                capacity = `- / ${numberToLocaleString(device2Metrics.originalCapacity, 0)}Ah`;
            }
        }
    } else {
        capacity = '-';
    }

    // lowest voltage
    if (device1Metrics.latestVoltage != null && device2Metrics.latestVoltage != null) {
        if (device1Metrics.latestVoltage < device2Metrics.latestVoltage) {
            latestVoltage = `${numberToLocaleString(device1Metrics.latestVoltage, 1)}V`;
        } else {
            latestVoltage = `${numberToLocaleString(device2Metrics.latestVoltage, 1)}V`;
        }
    } else {
        latestVoltage = '-';
    }

    // average temperature
    if (device1Metrics.latestTemperature != null && device2Metrics.latestTemperature != null) {
        const avgCalculation = (device1Metrics.latestTemperature + device2Metrics.latestTemperature) / 2;
        latestTemperature = `${numberToLocaleString(avgCalculation, 1)}°C`;
    } else {
        latestTemperature = '-';
    }
    // average current
    if (device1Metrics.latestCurrent != null && device2Metrics.latestCurrent != null) {
        const avgCalculation = (device1Metrics.latestCurrent + device2Metrics.latestCurrent) / 2;
        latestCurrent = `${numberToLocaleString(avgCalculation, 1)}A`;
    } else {
        latestCurrent = '-';
    }

    // depth of discharge
    if (device1Metrics.latestUsedCapacity != null && device2Metrics.latestUsedCapacity != null) {
        if (device1Metrics.latestUsedCapacity > device2Metrics.latestUsedCapacity) {
            latestDoD = `${numberToLocaleString(device1Metrics.latestUsedCapacity, 0)}Ah`;
        } else {
            latestDoD = `${numberToLocaleString(device2Metrics.latestUsedCapacity, 0)}Ah`;
        }
    } else {
        latestDoD = '-';
    }

    return (
        <>
            <div className='grid grid-cols-3'>
                <MetricDisplay label='Lowest remaining capacity' value={capacity} />
                <MetricDisplay label='Latest voltage' value={latestVoltage} />
                <MetricDisplay label='Latest temperature' value={latestTemperature} />
                <MetricDisplay label='Latest current' value={latestCurrent} />
                <MetricDisplay label='Depth of discharge' value={latestDoD} />
                <MetricDisplay label='State of Health' value='-' />
            </div>
        </>
    );
};
