/**
 * @generated SignedSource<<0ce439fd4f40f81068c6e520c7781a65>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DataDisplayCombinedQuery$variables = {
  begin?: string | null;
  end?: string | null;
  interval?: string | null;
  test1: string;
  test2: string;
};
export type DataDisplayCombinedQuery$data = {
  readonly test1: {
    readonly device: {
      readonly name: string;
    };
    readonly " $fragmentSpreads": FragmentRefs<"DataDisplayCombined_test">;
  } | null;
  readonly test2: {
    readonly device: {
      readonly name: string;
    };
    readonly " $fragmentSpreads": FragmentRefs<"DataDisplayCombined_test">;
  } | null;
};
export type DataDisplayCombinedQuery = {
  response: DataDisplayCombinedQuery$data;
  variables: DataDisplayCombinedQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "begin"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "end"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "interval"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "test1"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "test2"
},
v5 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "test1"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "kind": "Variable",
  "name": "begin",
  "variableName": "begin"
},
v8 = {
  "kind": "Variable",
  "name": "end",
  "variableName": "end"
},
v9 = {
  "kind": "Variable",
  "name": "interval",
  "variableName": "interval"
},
v10 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "timestamp",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "offset",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "average",
    "storageKey": null
  }
],
v11 = {
  "alias": null,
  "args": [
    (v7/*: any*/),
    (v8/*: any*/),
    (v9/*: any*/),
    {
      "kind": "Literal",
      "name": "unit",
      "value": "Volts"
    }
  ],
  "concreteType": "AggregatedTimestampMetric",
  "kind": "LinkedField",
  "name": "aggregatedVoltage",
  "plural": true,
  "selections": (v10/*: any*/),
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": [
    (v7/*: any*/),
    (v8/*: any*/),
    (v9/*: any*/),
    {
      "kind": "Literal",
      "name": "unit",
      "value": "Celsius"
    }
  ],
  "concreteType": "AggregatedTimestampMetric",
  "kind": "LinkedField",
  "name": "aggregatedTemperature",
  "plural": true,
  "selections": (v10/*: any*/),
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": [
    (v7/*: any*/),
    (v8/*: any*/),
    (v9/*: any*/),
    {
      "kind": "Literal",
      "name": "unit",
      "value": "Amps"
    }
  ],
  "concreteType": "AggregatedTimestampMetric",
  "kind": "LinkedField",
  "name": "aggregatedCurrent",
  "plural": true,
  "selections": (v10/*: any*/),
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": [
    (v7/*: any*/),
    (v8/*: any*/),
    (v9/*: any*/),
    {
      "kind": "Literal",
      "name": "unit",
      "value": "Watt"
    }
  ],
  "concreteType": "AggregatedTimestampMetric",
  "kind": "LinkedField",
  "name": "aggregatedPower",
  "plural": true,
  "selections": (v10/*: any*/),
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "commencedTime",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "completedTime",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "abortedTime",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "BatteryCoupDeFouet",
  "kind": "LinkedField",
  "name": "coupDeFouet",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "float",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "troughVoltage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "troughOffset",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "plateauVoltage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "plateauOffset",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dip",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v20 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Device",
    "kind": "LinkedField",
    "name": "device",
    "plural": false,
    "selections": [
      (v6/*: any*/)
    ],
    "storageKey": null
  },
  {
    "kind": "InlineDataFragmentSpread",
    "name": "DataDisplayCombined_test",
    "selections": [
      (v11/*: any*/),
      (v12/*: any*/),
      (v13/*: any*/),
      (v14/*: any*/),
      (v15/*: any*/),
      (v16/*: any*/),
      (v17/*: any*/),
      (v18/*: any*/),
      (v19/*: any*/)
    ],
    "args": null,
    "argumentDefinitions": [
      {
        "kind": "RootArgument",
        "name": "begin"
      },
      {
        "kind": "RootArgument",
        "name": "end"
      },
      {
        "kind": "RootArgument",
        "name": "interval"
      }
    ]
  }
],
v21 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "test2"
  }
],
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v23 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Device",
    "kind": "LinkedField",
    "name": "device",
    "plural": false,
    "selections": [
      (v6/*: any*/),
      (v22/*: any*/)
    ],
    "storageKey": null
  },
  (v11/*: any*/),
  (v12/*: any*/),
  (v13/*: any*/),
  (v14/*: any*/),
  (v15/*: any*/),
  (v16/*: any*/),
  (v17/*: any*/),
  (v18/*: any*/),
  (v19/*: any*/),
  (v22/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "DataDisplayCombinedQuery",
    "selections": [
      {
        "alias": "test1",
        "args": (v5/*: any*/),
        "concreteType": "DeviceBatteryTestResults",
        "kind": "LinkedField",
        "name": "batteryTestResult",
        "plural": false,
        "selections": (v20/*: any*/),
        "storageKey": null
      },
      {
        "alias": "test2",
        "args": (v21/*: any*/),
        "concreteType": "DeviceBatteryTestResults",
        "kind": "LinkedField",
        "name": "batteryTestResult",
        "plural": false,
        "selections": (v20/*: any*/),
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "DataDisplayCombinedQuery",
    "selections": [
      {
        "alias": "test1",
        "args": (v5/*: any*/),
        "concreteType": "DeviceBatteryTestResults",
        "kind": "LinkedField",
        "name": "batteryTestResult",
        "plural": false,
        "selections": (v23/*: any*/),
        "storageKey": null
      },
      {
        "alias": "test2",
        "args": (v21/*: any*/),
        "concreteType": "DeviceBatteryTestResults",
        "kind": "LinkedField",
        "name": "batteryTestResult",
        "plural": false,
        "selections": (v23/*: any*/),
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9ce78189bd47ed2a122e5b6cb8ec4ced",
    "id": null,
    "metadata": {},
    "name": "DataDisplayCombinedQuery",
    "operationKind": "query",
    "text": "query DataDisplayCombinedQuery(\n  $test1: ID!\n  $test2: ID!\n  $interval: Duration\n  $begin: Duration\n  $end: Duration\n) {\n  test1: batteryTestResult(id: $test1) {\n    device {\n      name\n      id\n    }\n    ...DataDisplayCombined_test\n    id\n  }\n  test2: batteryTestResult(id: $test2) {\n    device {\n      name\n      id\n    }\n    ...DataDisplayCombined_test\n    id\n  }\n}\n\nfragment DataDisplayCombined_test on DeviceBatteryTestResults {\n  aggregatedVoltage(unit: Volts, interval: $interval, begin: $begin, end: $end) {\n    timestamp\n    offset\n    average\n  }\n  aggregatedTemperature(unit: Celsius, interval: $interval, begin: $begin, end: $end) {\n    timestamp\n    offset\n    average\n  }\n  aggregatedCurrent(unit: Amps, interval: $interval, begin: $begin, end: $end) {\n    timestamp\n    offset\n    average\n  }\n  aggregatedPower(unit: Watt, interval: $interval, begin: $begin, end: $end) {\n    timestamp\n    offset\n    average\n  }\n  commencedTime\n  completedTime\n  abortedTime\n  state\n  coupDeFouet {\n    float\n    troughVoltage\n    troughOffset\n    plateauVoltage\n    plateauOffset\n    dip\n  }\n}\n"
  }
};
})();

(node as any).hash = "e852ccca4b98bbb8158ad61b38447922";

export default node;
