/**
 * @generated SignedSource<<7ac85656a2c86740a92c5574900e6d90>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FuelStats_data$data = {
  readonly fuelConsumed: number | null;
  readonly fuelRefuelled: number | null;
  readonly generator: {
    readonly fuelTankMetrics: {
      readonly capacity: number | null;
    };
  };
  readonly " $fragmentType": "FuelStats_data";
};
export type FuelStats_data$key = {
  readonly " $data"?: FuelStats_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"FuelStats_data">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "unit",
    "value": "Liter"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FuelStats_data",
  "selections": [
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "fuelConsumed",
      "storageKey": "fuelConsumed(unit:\"Liter\")"
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "fuelRefuelled",
      "storageKey": "fuelRefuelled(unit:\"Liter\")"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Device",
      "kind": "LinkedField",
      "name": "generator",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DeviceFuelTankMetrics",
          "kind": "LinkedField",
          "name": "fuelTankMetrics",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": (v0/*: any*/),
              "kind": "ScalarField",
              "name": "capacity",
              "storageKey": "capacity(unit:\"Liter\")"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "GeneratorRunReport",
  "abstractKey": null
};
})();

(node as any).hash = "32322917d085d41d65f4ea4df1c89266";

export default node;
