/**
 * @generated SignedSource<<2394a373eb216382e5e0fd20fb5e0c7b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type AuthenticationRole = "Administrator" | "AssetsReadOnly" | "AssetsReadWrite" | "ReadOnly" | "TasksBatteryHealthReadOnly" | "TasksBatteryHealthReadWrite" | "%future added value";
export type SortDirection = "Asc" | "Desc" | "%future added value";
export type UserSortField = "Email" | "Name" | "%future added value";
export type UserStatus = "Active" | "Inactive" | "Invited" | "PasswordResetRequired" | "Unknown" | "VerificationRequired" | "%future added value";
export type UserType = "External" | "Local" | "%future added value";
export type UserOrdering = {
  dir?: SortDirection | null;
  field: UserSortField;
};
export type ManageUsersTableQuery$variables = {
  orderBy?: UserOrdering | null;
  page?: number | null;
  search?: string | null;
};
export type ManageUsersTableQuery$data = {
  readonly overallUsers: {
    readonly total: number;
  };
  readonly users: {
    readonly data: ReadonlyArray<{
      readonly createdAt: string;
      readonly email: string | null;
      readonly enabled: boolean;
      readonly lastLogin: string | null;
      readonly name: string | null;
      readonly roles: ReadonlyArray<AuthenticationRole> | null;
      readonly status: UserStatus;
      readonly type: UserType;
      readonly updatedAt: string | null;
      readonly username: string;
    }>;
    readonly pageInfo: {
      readonly hasNext: boolean | null;
      readonly hasPrevious: boolean | null;
      readonly page: number;
      readonly size: number;
      readonly total: number;
    };
    readonly total: number;
  };
};
export type ManageUsersTableQuery = {
  response: ManageUsersTableQuery$data;
  variables: ManageUsersTableQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v1 = {
  "defaultValue": 1,
  "kind": "LocalArgument",
  "name": "page"
},
v2 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "search"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "orderBy",
        "variableName": "orderBy"
      },
      {
        "kind": "Variable",
        "name": "page",
        "variableName": "page"
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      }
    ],
    "concreteType": "PaginatedUsers",
    "kind": "LinkedField",
    "name": "users",
    "plural": false,
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "data",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "roles",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updatedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "enabled",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastLogin",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "PageInfo",
        "kind": "LinkedField",
        "name": "pageInfo",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "page",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "size",
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasNext",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasPrevious",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": "overallUsers",
    "args": null,
    "concreteType": "PaginatedUsers",
    "kind": "LinkedField",
    "name": "users",
    "plural": false,
    "selections": [
      (v3/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ManageUsersTableQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ManageUsersTableQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "29f505fc578d3369f55b65c7f43bc1bf",
    "id": null,
    "metadata": {},
    "name": "ManageUsersTableQuery",
    "operationKind": "query",
    "text": "query ManageUsersTableQuery(\n  $page: Int = 1\n  $search: String = \"\"\n  $orderBy: UserOrdering\n) {\n  users(page: $page, search: $search, orderBy: $orderBy) {\n    total\n    data {\n      username\n      status\n      name\n      email\n      roles\n      type\n      createdAt\n      updatedAt\n      enabled\n      lastLogin\n    }\n    pageInfo {\n      page\n      size\n      total\n      hasNext\n      hasPrevious\n    }\n  }\n  overallUsers: users {\n    total\n  }\n}\n"
  }
};
})();

(node as any).hash = "3f7e0c1f6782a139456df04fbbd7daf7";

export default node;
