/**
 * @generated SignedSource<<d35f7743cbafed83000bf4797408947a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type UnitApparentPower = "KiloVoltAmp" | "VoltAmp" | "%future added value";
export type UnitCharge = "AmpHour" | "MilliAmpHour" | "%future added value";
export type UnitCurrent = "Amps" | "Milliamps" | "%future added value";
export type UnitEnergy = "KiloWattHour" | "WattHour" | "%future added value";
export type UnitFlowRate = "LiterPerHour" | "USGallonPerHour" | "%future added value";
export type UnitPower = "KiloWatt" | "Watt" | "%future added value";
export type UnitPressure = "Bar" | "KiloPascal" | "PSI" | "Pascal" | "%future added value";
export type UnitReactivePower = "KiloVoltAmpReactive" | "VoltAmpReactive" | "%future added value";
export type UnitTemperature = "Celsius" | "Fahrenheit" | "Kelvin" | "%future added value";
export type UnitVoltage = "Millivolts" | "Volts" | "%future added value";
export type UnitVolume = "Liter" | "USGallon" | "%future added value";
export type authUserPreferencesQuery$variables = Record<PropertyKey, never>;
export type authUserPreferencesQuery$data = {
  readonly currentUser: {
    readonly preferences: {
      readonly timezone: string | null;
      readonly units: {
        readonly apparentPower: UnitApparentPower;
        readonly charge: UnitCharge;
        readonly current: UnitCurrent;
        readonly energy: UnitEnergy;
        readonly power: UnitPower;
        readonly pressure: UnitPressure;
        readonly reactivePower: UnitReactivePower;
        readonly temperature: UnitTemperature;
        readonly voltage: UnitVoltage;
        readonly volume: UnitVolume;
        readonly volumetricFlowRate: UnitFlowRate;
      };
    };
  };
};
export type authUserPreferencesQuery = {
  response: authUserPreferencesQuery$data;
  variables: authUserPreferencesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "concreteType": "UserPreferences",
  "kind": "LinkedField",
  "name": "preferences",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timezone",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserUnitPreferences",
      "kind": "LinkedField",
      "name": "units",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "apparentPower",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "charge",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "current",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "energy",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "power",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "pressure",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "reactivePower",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "temperature",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "voltage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "volume",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "volumetricFlowRate",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "authUserPreferencesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CurrentUser",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "authUserPreferencesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CurrentUser",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "423e5d8bc27348fa389ab8110715e457",
    "id": null,
    "metadata": {},
    "name": "authUserPreferencesQuery",
    "operationKind": "query",
    "text": "query authUserPreferencesQuery {\n  currentUser {\n    preferences {\n      timezone\n      units {\n        apparentPower\n        charge\n        current\n        energy\n        power\n        pressure\n        reactivePower\n        temperature\n        voltage\n        volume\n        volumetricFlowRate\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "187bc9e10578c02839678f8069c48f27";

export default node;
