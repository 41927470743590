/**
 * @generated SignedSource<<75c618d185a1bb25327cc8108b98e232>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MetricsViewLive_metrics$data = {
  readonly latestCurrent: number | null;
  readonly latestRemainingCapacity: number | null;
  readonly latestTemperature: number | null;
  readonly latestUsedCapacity: number | null;
  readonly latestVoltage: number | null;
  readonly originalCapacity: number | null;
  readonly " $fragmentType": "MetricsViewLive_metrics";
};
export type MetricsViewLive_metrics$key = {
  readonly " $data"?: MetricsViewLive_metrics$data;
  readonly " $fragmentSpreads": FragmentRefs<"MetricsViewLive_metrics">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "unit",
    "value": "AmpHour"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MetricsViewLive_metrics",
  "selections": [
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "originalCapacity",
      "storageKey": "originalCapacity(unit:\"AmpHour\")"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "unit",
          "value": "Volts"
        }
      ],
      "kind": "ScalarField",
      "name": "latestVoltage",
      "storageKey": "latestVoltage(unit:\"Volts\")"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "unit",
          "value": "Amps"
        }
      ],
      "kind": "ScalarField",
      "name": "latestCurrent",
      "storageKey": "latestCurrent(unit:\"Amps\")"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "unit",
          "value": "Celsius"
        }
      ],
      "kind": "ScalarField",
      "name": "latestTemperature",
      "storageKey": "latestTemperature(unit:\"Celsius\")"
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "latestRemainingCapacity",
      "storageKey": "latestRemainingCapacity(unit:\"AmpHour\")"
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "latestUsedCapacity",
      "storageKey": "latestUsedCapacity(unit:\"AmpHour\")"
    }
  ],
  "type": "DeviceBatteryMetrics",
  "abstractKey": null
};
})();

(node as any).hash = "af32c9160289692fa0771afe136f1d9b";

export default node;
