/**
 * @generated SignedSource<<53611f23fa4b5ee0d58d8967762975ea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DualPlaneResult_test$data = {
  readonly device: {
    readonly id: string;
    readonly name: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"CombinedTest_test" | "TestResultDisplay_test">;
  readonly " $fragmentType": "DualPlaneResult_test";
};
export type DualPlaneResult_test$key = {
  readonly " $data"?: DualPlaneResult_test$data;
  readonly " $fragmentSpreads": FragmentRefs<"DualPlaneResult_test">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DualPlaneResult_test",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Device",
      "kind": "LinkedField",
      "name": "device",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TestResultDisplay_test"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CombinedTest_test"
    }
  ],
  "type": "DeviceBatteryTestResults",
  "abstractKey": null
};

(node as any).hash = "02ccfce76aa45b51a04db4bae9a7a21c";

export default node;
