import React, { Dispatch, FC, ReactNode, SetStateAction, useMemo, useState } from 'react';

import {
    ChartAlert,
    ChartArea,
    ChartAreaAction,
    ChartMarker,
    CompareIcon,
    Domain,
    DomainAbsolute,
    DurationLineChart,
    ExternalLinkIcon,
    FilterContainer,
    JobsManagerIcon,
    LineChartSeries,
    LoadableContentArea,
    MagIcon,
    MenuItemType,
    Theme,
    TickGenerator,
    tickEvenlyForDuration,
    useExtendedNavigate,
} from '@accesstel/pcm-ui';

import { SingleSelectionList } from 'components/FilterSingleSelect/SingleSelectionList';
import { SelectTestPane, SelectionWithTestIds } from 'components/SelectTestPane';
import { TimeseriesOffsetPoint, TimeseriesOffsetSeries, asDurationLineData } from 'lib/dateChart';
import { numberToLocaleString } from 'lib/numberFormatters';
import { Paths } from 'lib/routes';
import { capitalize } from 'lib/textFormatters';
import { MetricsLineType, getUnit } from 'lib/units';
import { Duration } from 'luxon';

import { CompareURLParamTests } from '../test-compare/settings';
import { SelectAnotationsPane } from '../test-result-view/components/SelectAnnotationsPane';
import { AnnotationType } from '../test-result-view/lib/annotations';

export type ExtendedChartMarker = ChartMarker<Duration> & {
    adjustDomain?: boolean;
    annotationType: AnnotationType;
};

export interface MetricLineProps {
    type: MetricsLineType;

    data?: TimeseriesOffsetPoint[] | null;
    series?: TimeseriesOffsetSeries[] | null;
    comparisonData?: TimeseriesOffsetPoint[];
    error?: boolean;
    onRetry?: () => void;

    useStdDev?: boolean;

    xDomain: Domain<Duration>;
    xMinTicks?: number;
    onZoom?: (domain: DomainAbsolute<Duration> | null) => void;
    markers?: ExtendedChartMarker[];

    menuItems?: MenuItemType[];
    menuSelected?: string;
    menuPlaceholder?: string;

    testCompare?: {
        currentTestId: string;
        testMenuItems: SelectionWithTestIds[];
        selectedSelection: SelectionWithTestIds | null;
        setSelectedSelection: Dispatch<SetStateAction<SelectionWithTestIds | null>>;
    };

    /**
     * Show a an overlay over the chart with some alert.
     * This will also grey out the chart at the bank to indicate that the chart is not interactive
     */
    unsupported?: boolean;
    /**
     * If supported is set to true, this will default to 'Data Unavailable'.
     * Use this argument to show a custom title instead
     */
    unsupportedTitle?: string;
    /**
     * If supported is set to true, this will default to
     * 'No ${type} sensor installed on this device' based on the type argument.
     * Use this argument to show a custom message instead
     */
    unsupportedMessage?: string;

    collapsible?: boolean;
    showLegend?: boolean;
    legendOnClick?: (name: string, id?: string) => void;

    allAnnotations?: AnnotationType[];
    visibleAnnotations?: AnnotationType[];
    onSetVisibleAnnotations?: (annotations: AnnotationType[]) => void;

    aspectRatio?: number;
    /**
     * Height of the chart. Must be represented as tailwindcss class
     * @default 'h-64'
     */
    height?: string;
}

export const MetricLine: FC<MetricLineProps> = ({
    type,
    data,
    series,
    comparisonData,
    error,
    onRetry,
    useStdDev,
    xDomain,
    xMinTicks = 0,
    onZoom,
    markers,
    menuItems,
    menuSelected,
    menuPlaceholder,
    unsupported = false,
    unsupportedTitle = 'Data Unavailable',
    unsupportedMessage,
    testCompare,
    collapsible,
    showLegend = false,
    legendOnClick,
    allAnnotations,
    visibleAnnotations,
    onSetVisibleAnnotations,
    aspectRatio = 4.5,
    height = 'h-64',
}) => {
    const navigate = useExtendedNavigate();

    const [isZoomPaneOpen, setIsZoomPaneOpen] = useState(false);
    const [isSelectTestPaneOpen, setIsSelectTestPaneOpen] = useState(false);
    const [isSelectAnnotationsPaneOpen, setIsSelectAnnotationsPaneOpen] = useState(false);
    const UNIT = getUnit(type);

    const title = capitalize(type);

    const getYRange = (type: MetricsLineType): Domain<number> => {
        switch (type) {
            case 'current':
                return [0, 10];
            case 'voltage':
                return [35, 60];
            case 'power':
                return [0, 100];
            case 'temperature':
                return [0, 50];
            case 'load':
                return [0, 10];
        }
    };

    const processedSeries = useMemo(() => {
        let processed: LineChartSeries<Duration>[] = [];

        if (series) {
            processed = series.map(({ points, ...rest }) => ({
                data: asDurationLineData(points),
                ...rest,
            }));
            processed = processed.filter(inputSeries => inputSeries.data.length > 0);
        } else if (data) {
            processed.push({
                data: asDurationLineData(data),
                color: Theme.coralRegular,
                name: '',
                stdDev: useStdDev,
            });
            if (comparisonData) {
                processed.push({
                    data: asDurationLineData(comparisonData!),
                    color: Theme.mauveRegular,
                    name: '',
                    strokeDash: '1 2',
                    stdDev: useStdDev,
                });
            }
        } else {
            // no data
            return [
                {
                    data: asDurationLineData([]),
                    color: Theme.coralRegular,
                    name: '',
                    stdDev: useStdDev,
                },
            ];
        }

        if (processed.length === 0) {
            return null;
        }

        return processed;
        // NOTE: xDomain is present to force a new instance of series. Required for animations to work
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, series, comparisonData, xDomain]);

    const buffer = type === 'voltage' || type === 'temperature' ? 2 : undefined;
    const yTicks = type === 'voltage' ? 5 : 8;

    const yDomain = useMemo(
        () => computeYDomainFor(processedSeries ?? [], xDomain, getYRange(type), buffer, markers),
        [processedSeries, xDomain, type, buffer, markers]
    );

    const canZoom = useMemo(() => {
        if (Duration.isDuration(xDomain[0]) && Duration.isDuration(xDomain[1])) {
            const span = xDomain[1].as('minutes') - xDomain[0].as('minutes');
            if (span <= 1) {
                return 'zoom-out';
            }
        }
        return true;
    }, [xDomain]);

    const zoomAction = menuItems
        ? createMenuAction({
              menuItems,
              menuSelected,
              isOpen: isZoomPaneOpen,
              setIsOpen: setIsZoomPaneOpen,
              variant: 'dark',
              menuPlaceholder,
          })
        : undefined;

    // FIXME: Move this menu item handling to the parent component.
    const chartActions: ChartAreaAction[] = [];

    if (allAnnotations && visibleAnnotations && onSetVisibleAnnotations) {
        // ensure visible annotations only contains available annotation types
        const validVisibleAnnotations = visibleAnnotations.filter(annotation => allAnnotations.includes(annotation));

        chartActions.push({
            buttonIcon: <JobsManagerIcon />,
            buttonText: `Annotations (${validVisibleAnnotations.length})`,
            embeddedComponent: isSelectAnnotationsPaneOpen && (
                <SelectAnotationsPane
                    options={allAnnotations}
                    selected={validVisibleAnnotations}
                    onClose={() => setIsSelectAnnotationsPaneOpen(false)}
                    onSubmit={annotations => onSetVisibleAnnotations(annotations)}
                />
            ),
            onClick: () => setIsSelectAnnotationsPaneOpen(true),
            variant: 'dark',
        });
    }

    if (testCompare) {
        const selectTestAction: ChartAreaAction = {
            buttonIcon: <CompareIcon />,
            buttonText: 'Quick Compare',
            embeddedComponent: isSelectTestPaneOpen && (
                <SelectTestPane
                    current={testCompare.selectedSelection}
                    tests={testCompare.testMenuItems.length > 0 ? testCompare.testMenuItems : []}
                    onClose={() => setIsSelectTestPaneOpen(false)}
                    onSubmit={selection => {
                        if (selection === testCompare.selectedSelection) {
                            testCompare.setSelectedSelection(null); // unselect
                        } else {
                            testCompare.setSelectedSelection(selection);
                        }
                    }}
                />
            ),
            onClick: () => setIsSelectTestPaneOpen(true),
            variant: 'dark',
        };

        const navigateToComparePage: ChartAreaAction = {
            buttonIcon: <ExternalLinkIcon />,
            buttonText: 'Open Compare View',
            variant: 'dark',
            onClick: () => {
                const testIds: string[] = [];
                if (testCompare.currentTestId) {
                    testIds.push(testCompare.currentTestId);
                }
                if (testCompare.selectedSelection) {
                    testIds.push(...testCompare.selectedSelection.ids);
                }

                const testIdsParam = testIds.join(',');

                navigate({
                    pathname: Paths.TestsCompare,
                    search: {
                        [CompareURLParamTests]: testIdsParam,
                    },
                });
            },
        };

        chartActions.push(selectTestAction);
        chartActions.push(navigateToComparePage);
    }
    if (zoomAction) {
        chartActions.push(zoomAction);
    }

    return (
        <ChartArea title={title} actions={chartActions} disabled={unsupported} enableCollapseMode={collapsible}>
            <LoadableContentArea
                data={processedSeries}
                render={series => {
                    const maxDataLength = series.reduce(
                        (highest, inputSeries) => Math.max(highest, inputSeries.data.length),
                        xMinTicks
                    );

                    return (
                        <div className='relative'>
                            {unsupported && (
                                <ChartAlert
                                    title={unsupportedTitle}
                                    message={unsupportedMessage ?? `No ${type} sensor installed on this device`}
                                    light={false}
                                />
                            )}
                            <DurationLineChart
                                series={series}
                                unit={UNIT}
                                yTicks={yTicks}
                                xTicks={computeChartTicks(maxDataLength)}
                                axisUnits
                                aspectRatio={aspectRatio}
                                formatTooltipValue={value => valueFormatterWPrecision(value, UNIT)}
                                yDomain={yDomain}
                                xDomain={xDomain}
                                zoom={canZoom}
                                onZoom={onZoom}
                                margin={{ left: 5, bottom: 5, top: 5, right: 30 }}
                                start={Duration.fromObject({ seconds: 0 })}
                                end
                                syncId='single-result-charts'
                                stdDev={useStdDev}
                                stdDevLabel='1 standard deviation'
                                markers={markers}
                                legend={showLegend}
                                legendLayout='horizontal'
                                legendWrapperStyle={{ paddingBottom: '0.725rem' }}
                                legendOnClick={legendOnClick}
                                legendSeparate={true}
                            />
                        </div>
                    );
                }}
                error={error}
                onRetry={onRetry}
                className={height}
                variant='white'
            />
        </ChartArea>
    );
};

function computeYDomainFor(
    series: LineChartSeries<Duration>[],
    xDomain: Domain<Duration>,
    emptyDomain: Domain<number> = ['auto', 'auto'],
    domainBuffer = 10,
    markers?: ExtendedChartMarker[]
): Domain<number> {
    let isAbsoluteDomain = true;
    if (!Duration.isDuration(xDomain[0]) || !Duration.isDuration(xDomain[1])) {
        isAbsoluteDomain = false;
    }

    let min: number | null = null;
    let max: number | null = null;
    for (const currentSeries of series) {
        for (const data of currentSeries.data) {
            if (!isAbsoluteDomain || (data.key >= xDomain[0] && data.key <= xDomain[1])) {
                if (data.value == null) {
                    continue;
                }

                if (min === null || data.value < min) {
                    min = data.value;
                }

                if (max === null || data.value > max) {
                    max = data.value;
                }

                if (data.stdDev) {
                    min = Math.min(min, data.stdDev[0], data.stdDev[1]);
                    max = Math.max(max, data.stdDev[0], data.stdDev[1]);
                }
            }
        }
    }

    if (markers) {
        for (const marker of markers) {
            if (!marker.adjustDomain) {
                continue;
            }

            let yMin: number | null = null;
            let yMax: number | null = null;

            switch (marker.type) {
                case 'point':
                    if (isAbsoluteDomain && (marker.x < xDomain[0] || marker.x > xDomain[1])) {
                        continue;
                    }
                    yMin = marker.y;
                    break;
                case 'horizontal':
                    yMin = marker.y;
                    break;
                case 'line':
                case 'area':
                    if (isAbsoluteDomain && (marker.x1 > xDomain[1] || marker.x2 < xDomain[0])) {
                        continue;
                    }
                    yMin = marker.y1;
                    yMax = marker.y2;
                    break;
                case 'area-horizontal':
                    yMin = marker.y1;
                    yMax = marker.y2;
                    break;
                default:
                    continue;
            }

            if (yMin !== null && (min === null || yMin < min)) {
                min = yMin;
            }

            if (yMax !== null && (max === null || yMax > max)) {
                max = yMax;
            }
        }
    }

    if (min !== null && max !== null) {
        return [min - domainBuffer, max + domainBuffer];
    } else {
        return emptyDomain;
    }
}

function computeChartTicks(
    dataCount: number,
    maxTicks = 14
): number | Duration[] | TickGenerator<Duration> | undefined {
    if (dataCount === 0) {
        return;
    }

    if (dataCount < maxTicks) {
        maxTicks = Math.ceil(dataCount);
    }

    if (maxTicks === 1) {
        return 1;
    }

    return tickEvenlyForDuration(maxTicks);
}

function valueFormatterWPrecision(value: number | null, unit?: string, precision = 2): string {
    if (value === null) {
        return '';
    }

    const formattedValue = numberToLocaleString(value, precision);

    if (unit) {
        return `${formattedValue}${unit}`;
    } else {
        return formattedValue;
    }
}

export const createMenuAction = ({
    menuItems,
    menuSelected,
    isOpen,
    setIsOpen,
    variant = 'light',
    menuPlaceholder = 'Unknown',
}: {
    menuItems: MenuItemType[];
    menuSelected: string | undefined;
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    variant?: 'light' | 'dark';
    menuPlaceholder?: string;
}) => {
    const activeMenuItem = menuItems.find(menu => menu.id === menuSelected);
    const content: ReactNode | undefined = menuItems ? (
        <SingleSelectionList<MenuItemType>
            items={menuItems}
            onSelect={item => {
                item.onClick(item.id);
                setIsOpen(false);
            }}
            selectedItem={activeMenuItem}
            renderItem={item => item.name}
        />
    ) : undefined;

    const action: ChartAreaAction = {
        buttonIcon: <MagIcon />,
        buttonText: activeMenuItem?.name ?? menuPlaceholder,
        embeddedComponent: isOpen && (
            <FilterContainer
                title={menuPlaceholder}
                onClose={() => setIsOpen(false)}
                primaryContent={content}
                hideConfirmButton
                hideClearButton
            />
        ),
        onClick: () => setIsOpen(true),
        variant,
    };

    return action;
};
