import React, { FC, useCallback } from 'react';
import { fetchQuery, useFragment, useMutation, useRelayEnvironment } from 'react-relay';

import { FormArea, useToast } from '@accesstel/pcm-ui';

import { captureException, captureMessage } from '@sentry/react';
import graphql from 'babel-plugin-relay/macro';
import { Formik, FormikHelpers } from 'formik';

import { TimezonePreset, getBrowserTimezone, makeTimezoneOption } from '../lib/timezone';
import { TimezoneSettingsFormValues } from '../schema';
import { TimezoneSettingsValidationSchema } from '../validation';
import { TimezoneSettingsContent } from './TimezoneSettingsContent';
import {
    TimezoneSettingsFragment$data,
    TimezoneSettingsFragment$key,
} from './__generated__/TimezoneSettingsFragment.graphql';
import {
    TimezoneSettingsUpdateMutation,
    UserPreferenceIn,
} from './__generated__/TimezoneSettingsUpdateMutation.graphql';

export interface TimezoneSettingsProps {
    settings: TimezoneSettingsFragment$key;
}

export const TimezoneSettings: FC<TimezoneSettingsProps> = ({ settings }) => {
    const { show } = useToast();
    const data = useFragment(TimezoneSettingsFragment, settings);

    const [saveTimezoneSettings] = useMutation<TimezoneSettingsUpdateMutation>(UpdateMutation);
    const environment = useRelayEnvironment();

    const handleSubmit = useCallback(
        (
            values: TimezoneSettingsFormValues,
            { setSubmitting, setFieldError }: FormikHelpers<TimezoneSettingsFormValues>
        ) => {
            const initialValues = toInitialValues(data);
            const submitData = toSubmitData(values, initialValues);

            if (!submitData) {
                setSubmitting(false);
                return;
            }

            setSubmitting(true);
            saveTimezoneSettings({
                variables: {
                    input: submitData,
                },
                onCompleted: data => {
                    if (data.updateCurrentUserPreferences.success) {
                        show({
                            text: 'Saved timezone preferences',
                            variant: 'info',
                        });

                        // Reload the form
                        fetchQuery(environment, ReloadQuery, {}).subscribe({
                            next() {
                                setSubmitting(false);
                            },
                            error(error: unknown) {
                                setSubmitting(false);
                                captureException(error, scope => {
                                    scope.setTag('Function', 'Timezone reload');
                                    return scope;
                                });
                            },
                        });
                    } else {
                        setSubmitting(false);
                        show({
                            text: 'Unable to save changes.',
                            variant: 'error',
                        });

                        for (const problem of data.updateCurrentUserPreferences.problems ?? []) {
                            if (problem === 'InvalidTimezone') {
                                setFieldError('name', 'Unknown timezone');
                                captureMessage('API responsed with invalid timezone on "official" timezone', scope =>
                                    scope.setExtra('timezone', values.timezone.name)
                                );
                            }
                        }
                    }
                },
                onError: error => {
                    setSubmitting(false);
                    show({
                        text: 'Unable to save changes. Please try again later',
                        variant: 'error',
                    });
                    captureException(error, scope => {
                        scope.setTag('Function', 'Personal timezone preferences save');
                        return scope;
                    });
                },
            });
        },
        [data, environment, saveTimezoneSettings, show]
    );

    return (
        <FormArea label='Timezone' blurb='Preferences for which timezone dates are displayed'>
            <Formik
                initialValues={toInitialValues(data)}
                validationSchema={TimezoneSettingsValidationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                <TimezoneSettingsContent />
            </Formik>
        </FormArea>
    );
};

export const TimezoneSettingsFragment = graphql`
    fragment TimezoneSettingsFragment on UserPreferences {
        timezone
    }
`;

const UpdateMutation = graphql`
    mutation TimezoneSettingsUpdateMutation($input: UserPreferenceIn!) {
        updateCurrentUserPreferences(preferences: $input) {
            success
            problems
        }
    }
`;

const ReloadQuery = graphql`
    query TimezoneSettingsReloadQuery {
        currentUser {
            preferences {
                ...TimezoneSettingsFragment
            }
        }
    }
`;

function toInitialValues(data: TimezoneSettingsFragment$data): TimezoneSettingsFormValues {
    if (data.timezone) {
        return {
            preset: TimezonePreset.Manual,
            timezone: makeTimezoneOption(data.timezone),
        };
    } else {
        return {
            preset: TimezonePreset.Automatic,
            timezone: getBrowserTimezone(),
        };
    }
}

function toSubmitData(
    values: TimezoneSettingsFormValues,
    initialValues: TimezoneSettingsFormValues
): UserPreferenceIn | null {
    const submitData: UserPreferenceIn = {};

    let changed = false;

    let initialZone: string | null;
    if (initialValues.preset === TimezonePreset.Automatic) {
        initialZone = null;
    } else {
        initialZone = initialValues.timezone.name;
    }

    let currentZone: string | null;
    if (values.preset === TimezonePreset.Automatic) {
        currentZone = null;
    } else {
        currentZone = values.timezone.name;
    }

    if (initialZone !== currentZone) {
        submitData.timezone = currentZone;
        changed = true;
    }

    if (!changed) {
        return null;
    }

    return submitData;
}
