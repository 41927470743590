import React, { FC } from 'react';
import { useFragment } from 'react-relay';

import { MetricDisplay } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { numberToLocaleString } from 'lib/numberFormatters';

import { MetricsViewLive_metrics$key } from './__generated__/MetricsViewLive_metrics.graphql';

interface MetricsViewLiveProps {
    metrics: MetricsViewLive_metrics$key | null;
}

export const MetricsViewLive: FC<MetricsViewLiveProps> = ({ metrics }) => {
    const result = useFragment<MetricsViewLive_metrics$key>(
        graphql`
            fragment MetricsViewLive_metrics on DeviceBatteryMetrics {
                originalCapacity(unit: AmpHour)
                latestVoltage(unit: Volts)
                latestCurrent(unit: Amps)
                latestTemperature(unit: Celsius)
                latestRemainingCapacity(unit: AmpHour)
                latestUsedCapacity(unit: AmpHour)
            }
        `,
        metrics
    );

    let capacity;
    if (result && result.originalCapacity !== null && result.latestRemainingCapacity !== null) {
        capacity = `${numberToLocaleString(result.latestRemainingCapacity, 0)} / ${numberToLocaleString(
            result.originalCapacity,
            0
        )}Ah`;
    } else if (result && result.originalCapacity !== null) {
        capacity = `- / ${numberToLocaleString(result.originalCapacity, 0)}Ah`;
    } else {
        capacity = '-';
    }

    let endVoltage;
    if (result && result.latestVoltage != null) {
        endVoltage = `${numberToLocaleString(result.latestVoltage, 1)}V`;
    } else {
        endVoltage = '-';
    }

    let avgTemperature;
    if (result && result.latestTemperature != null) {
        avgTemperature = `${numberToLocaleString(result.latestTemperature, 1)}°C`;
    } else {
        avgTemperature = '-';
    }

    let avgCurrent;
    if (result && result.latestCurrent != null) {
        avgCurrent = `${numberToLocaleString(result.latestCurrent, 1)}A`;
    } else {
        avgCurrent = '-';
    }

    let discharged;
    if (result && result.latestUsedCapacity != null) {
        discharged = `${numberToLocaleString(result.latestUsedCapacity, 0)}Ah`;
    } else {
        discharged = '-';
    }

    return (
        <>
            <div className='grid grid-cols-3'>
                <MetricDisplay label='Remaining capacity' value={capacity} />
                <MetricDisplay label='Latest voltage' value={endVoltage} />
                <MetricDisplay label='Latest temperature' value={avgTemperature} />
                <MetricDisplay label='Latest current' value={avgCurrent} />
                <MetricDisplay label='Depth of discharge' value={discharged} />
                <MetricDisplay label='State of Health' value='-' />
            </div>
        </>
    );
};
